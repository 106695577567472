import { CheckCircleTwoTone, CloseCircleTwoTone, PlayCircleTwoTone } from "@ant-design/icons";
import { useQuery } from '@apollo/client';
import { PreviewApi } from '@zzwing/react-image';
import { Pagination, Space, Table } from 'antd';
import React, { useEffect } from 'react';
import { usePager } from '../../../../commons/hooks';
import { GetDataError, Spinner } from '../../../../components/common';
import { ZHENGZHAO_SEARCH } from '../../../../graphql/piaozheng';

import './zhengzhao.scss'



const columns = [
  {
    title: '档口名称',
    dataIndex: 'dkName',
    key: 'dkName',
  },
  {
    title: '供应商名称',
    dataIndex: 'supplierName',
    key: 'supplierName',
  },
  {
    title: '证照名称',
    dataIndex: 'zzType',
    key: 'zzType',
  },
  {
    title: '状态',

    key: 'status',
    render: (data) => (
      data.status ? (<CheckCircleTwoTone twoToneColor="#52c41a" />) :
        (<CloseCircleTwoTone twoToneColor="#9B9393" />)
    )
  },

  {
    title: '查看',
    key: 'action',
    render: (data) => {
      if (data.images.length > 0) {
        return (<Space size="middle">

          <PlayCircleTwoTone onClick={() => {
            if (data.images.length > 0) {
              PreviewApi.preview(0, data.images)
            }

          }} />
        </Space >)

      } else {
        return <PlayCircleTwoTone twoToneColor="#9B9393" />
      }

    },
  }
];
export default function ZhengzhaoTable({
  zzType,
  dkId,
  supplierName,
}) {
  // const [pager, setPager] = useState({ current: 1, pageSize: 10 });
  const { pager, handlePagerChange } = usePager({});
  const { data, loading, error, refetch } = useQuery(
    ZHENGZHAO_SEARCH,
    {
      variables: {
        ...pager,
        zzType,
        supplierName, dkId
      },
    },
  );

  useEffect(() => {
    // console.log(pager, "refetch");
    refetch({ ...pager, })
  },
    [pager, refetch])

  // const handlePagerChange = (page) => {
  //   setPager({ ...pagination, current: page.current });
  // };


  if (error) return <GetDataError />;
  if (loading) return <Spinner />;
  const items = data.searchZhengzhao.items;

  const dataSource = items.map(item => {
    const imageJson = item.images;
    const images = JSON.parse(imageJson);
    const imageUrls = images ?
      images.map(img => `https://piaozheng.smqsmq.com/${img}`) : [];

    let zzTypeName = ""
    if (item.zzType === "YYZZ") {
      zzTypeName = "营业执照"
    } else if (item.zzType === "SPXKZ") {
      zzTypeName = "食品经营许可证"
    } else {
      zzTypeName = "其他"
    }


    return {
      supplierName: item.supplierCate.name,
      dkName: item.company.name,
      status: images && images.length > 0,
      zzType: zzTypeName,
      images: imageUrls,
      id: item.id
    }
  })

  // console.log("pager===", pager);
  const pagination = { pageSize: pager.pageSize, current: pager.pageNum, total: data.searchZhengzhao.total, };


  return (
    <div className="x">
      <Table columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handlePagerChange}
        pagination={false}
        rowKey="id" />

      <Pagination
        {...pagination}
        onChange={handlePagerChange}
        className="pager">
      </Pagination>
    </div>
  )

}
