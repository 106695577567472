import gql from 'graphql-tag';

export const PIAOZHENG_FRAGMENT = gql`
  fragment Piaozheng on PiaozhengType {
    id
    name
    companyId
    fromPiaozhengId
    date
    desc
    images
  }
`;

export const PIAOZHENG_INDEX_PAGE = gql`
  query PiaozhengCategories {
    piaozhengCategories {
      id
      name
    }
    mySupplierCatesPage(pageNum: 1, pageSize: 999) {
      total
      items {
        id
        name
      }
    }

    myCompany {
      id
      name
      organization {
        name
        companies {
          id
          name
        }
      }
    }
  }
`;

export const PIAOZHENG_PAGE = gql`
  query PiaozhengPage(
    $companyId: Int
    $categoryId: Int
    $supplierCateIds: [Int]
    $startDate: String
    $endDate: String
    $pageNum: Int
    $pageSize: Int
  ) {
    piaozhengPage(
      companyId: $companyId
      categoryId: $categoryId
      supplierCateIds: $supplierCateIds
      startDate: $startDate
      endDate: $endDate
      pageNum: $pageNum
      pageSize: $pageSize
    ) {
      total
      items {
        ...Piaozheng
        categories {
          id
          name
        }
        suppliers {
          id
          name
        }
      }
    }
  }
  ${PIAOZHENG_FRAGMENT}
`;

export const ZHENGZHAO_SEARCH = gql`
  query searchZhengzhao(
    $dkId: Int
    $zzType: ZhengzhaoEnum
    $supplierName: String,
    $pageNum:Int,
    $pageSize:Int
  ) {
    searchZhengzhao(
      dkId: $dkId
      zzType: $zzType
      supplierName: $supplierName,
      pageNum:$pageNum,
      pageSize:$pageSize
    ) {
      total
      items {
        id
        images
        company {
          name
        }
        supplierCate {
          name
        }
        zzType
      }
    }
  }
`;

export const ZhengzhaoEnum = {
  YYZZ: 'yyzz',
  SPXKZ: 'spxkz',
  OTHERS: 'others',
};
export const ALL_DKS = gql`
  query allDks {
    allDks {
      name
      id
    }
  }
`;

/* Mutations */
export const CREATE_PIAOZHENG_MUTATION = gql`
  mutation CREATE_PIAOZHENG_MUTATION($piaozheng: PiaozhengInput!) {
    createPiaozheng(piaozheng: $piaozheng) {
      ok
    }
  }
`;

export const UPDATE_PIAOZHENG_MUTATION = gql`
  mutation UPDATE_PIAOZHENG_MUTATION($piaozheng: PiaozhengInput!) {
    updatePiaozheng(piaozheng: $piaozheng) {
      ok
    }
  }
`;

export const DELETE_PIAOZHENG_MUTATION = gql`
  mutation DELETE_PIAOZHENG_MUTATION($piaozhengId: Int!) {
    deletePiaozheng(piaozhengId: $piaozhengId) {
      ok
    }
  }
`;

export const DELETE_PIAOZHENG_IMAGE = gql`
  mutation DeletePiaozhengImage(
    $piaozhengId: Int!
    $imageName: String!
  ) {
    deletePiaozhengImage(
      piaozhengId: $piaozhengId
      imageName: $imageName
    ) {
      ok
    }
  }
`;

/* Category Mutation */
export const CREATE_PIAOZHENG_CATEGORY_MUTATION = gql`
  mutation CREATE_PIAOZHENG_CATEGORY_MUTATION(
    $categoryName: String!
  ) {
    createPiaozhengCategory(categoryName: $categoryName) {
      ok
      categoryId
    }
  }
`;

export const UPDATE_PIAOZHENG_CATEGORY_MUTATION = gql`
  mutation UPDATE_PIAOZHENG_CATEGORY_MUTATION(
    $categoryId: Int!
    $categoryName: String!
  ) {
    updatePiaozhengCategory(
      categoryId: $categoryId
      categoryName: $categoryName
    ) {
      ok
    }
  }
`;

/* Zhengzhao 证照 Mutations */
export const CREATE_SUPPLIER_CATE_MUTATION = gql`
  mutation CREATE_SUPPLIER_CATE($name: String!) {
    createSupplierCate(name: $name) {
      ok
      cateId
      error
    }
  }
`;

export const UPDATE_SUPPLIER_CATE_MUTATION = gql`
  mutation UPDATE_SUPPLIER_CATE_MUTATION(
    $categoryId: Int!
    $categoryName: String!
  ) {
    updateSupplierCate(
      categoryId: $categoryId
      categoryName: $categoryName
    ) {
      ok
    }
  }
`;

export const DELETE_SUPPLIER_CATE = gql`
  mutation DELETE_SUPPLIER_CATE($cateId: Int!) {
    deleteSupplierCate(cateId: $cateId) {
      ok
      error
    }
  }
`;
