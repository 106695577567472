import { BuyersPage } from "./index";
import AppLayout from "../../../components/_layout";


const buyerRoutes = {
  path: "/buyers",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: BuyersPage,
    },
  ],
};

export default buyerRoutes;
