import React, { useState, FC } from "react";
import { Tabs } from "antd";
import OrderModeTab from "./components/OrderModeTab";
import { OrderStatusTypes } from "./components/order.model";
import OrderGroupTable from "./components/OrderGroupTable";
const { TabPane } = Tabs;

const PurchaseOrders: FC = () => {
  const [update, setUpdate] = useState(1);
  const purchaseOrderCount = [2, 3, 4, 5, 0];
  return (
    <>
      <Tabs
        defaultActiveKey={OrderStatusTypes.PENDING_FORWARD.key}
        animated={false}
      >
        {Object.entries(OrderStatusTypes).map(([key, value], idx) => {
          if (key !== OrderStatusTypes.RETURNED.key) {
            return (
              <TabPane
                key={key}
                tab={
                  <OrderModeTab dot={purchaseOrderCount[idx]}>
                    {value.tabText}
                  </OrderModeTab>
                }
              >
                <OrderGroupTable
                  orderMode="purchase"
                  orderStatus={key}
                  refreshCount={() => setUpdate(update + 1)}
                />
              </TabPane>
            );
          } else {
            return <></>;
          }
        })}
      </Tabs>
    </>
  );
};

export default PurchaseOrders;
