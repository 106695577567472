import React, { useState } from "react";
import { Divider } from "antd";
import { KeyValueModel } from "../../commons/models";
import AddNewForm from "./AddNewForm";
import EditForm from "./EditForm";

const KeyValueCollectionEditor = ({ items, onSave }) => {
  // currentId is used to manage the selected EditForm
  const [currentId, setCurrentId] = useState(0);

  //onCurrentIdChanged is parant's event handler used to update currentId that will be passed in to each EditForm
  function handleOnCurrentIdChanged(newValue) {
    setCurrentId(newValue);
  }

  return (
    <>
      <AddNewForm
        onSave={onSave}
        currentId={currentId}
        onCurrentIdChanged={handleOnCurrentIdChanged}
      />
      <br />
      {items.map((item: KeyValueModel) => (
        <EditForm
          key={item.id}
          currentId={currentId}
          onCurrentIdChanged={handleOnCurrentIdChanged}
          item={item}
          onSave={onSave}
        />
      ))}
    </>
  );
};

export default KeyValueCollectionEditor;
