import { ProductPage } from "./index";
import ProductBulkPage from "./bulk/index";
import SupplierProductImportPage from "./bulk/SupplierProductImportPage";
import AppLayout from "../../components/_layout";

const productRoutes = {
  path: "/products",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: ProductPage,
    },
    {
      path: "/bulk",
      component: ProductBulkPage,
    },
    {
      path: "import_from_supplier",
      component: SupplierProductImportPage,
    },
  ],
};

export default productRoutes;
