export interface KeyValueModel {
  id: number;
  name: string;
  description?: string;
  category?: string;
}

export enum OperationType {
  Add,
  Edit,
  Delete,
  Cancel,
}
// export type OperationType = "Add" | "Edit" | "Delete" | "Cancel";

// KeyValueCollectionEditor has used ItemToSave for saving the category item in product-category
export interface ItemToSave {
  item: any;
  operationType: OperationType;
}

export interface Company {
  id: number;
  name: string;
  contact: string;
  full_name?: string;
  owner_id?: number;
  // logo_url: string;district_code: string;address: string;contact_phone: string;
  // telephone: string; tax: string; website: string; about: string; is_activated: boolean; created_at: Date; updated_at: Date;
}

export interface Company_relation {
  // buyer_id: number; supplier_id: number;
  relationStatus: string;
  relationType: string;
  inviterId: number;
  allowModifyOrderQty: boolean;
  allowModifyOrderPrice: boolean;
  allowAutoConfirmOrder: boolean;
  allowAutoDeliverOrder: boolean;
  // created_at: Date; updated_at: Date;
}

// https://stackoverflow.com/questions/56063753/typescript-change-conflicting-property-name-when-extending-interface
// export interface MyCompany extends Company, Company_relation {}
export interface MyCompany extends Company, Company_relation {
  validation?: Array<string>;
}
