import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import APPCONFIG from "../config";
import typeDefs from "./_typeDefs";
import { IS_LOGGED_IN } from "./login";
import { decodeJwt } from "../commons/utils";
import { ALL_HEADER_DATA } from "./global";
import { message } from 'antd';


console.log("base url...", process.env.REACT_APP_BASE_URL)

const uri = process.env.REACT_APP_BASE_URL;
const cache = new InMemoryCache();

const defaultOptions = {
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
  uri,
};
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      if (
        graphQLError.extensions &&
        graphQLError.extensions.code === "FORBIDDEN"
      ) {
        message.error("Forbidden: " + graphQLError.message);
      } else {
        console.log(
          `[GraphQL error]: Message: ${graphQLError.message}, %o`,
          graphQLError
        );
        message.error(graphQLError.message);
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: %o`, networkError);
    message.error("Network error");
  }
});

const createClient = (tokenValue, companyId) => {
  const link = new createUploadLink({
    uri,
    headers: {
      Authorization: `Bearer ${tokenValue}`,
      company_id: companyId ? companyId : 0,
      "client-name": "smq[pc_client]",
      "client-version": "1.0.0",
    },
  });

  const client = new ApolloClient({
    defaultOptions,
    cache: cache,
    link: ApolloLink.from([errorLink, link]),
    // resolvers,
    typeDefs,
  });

  const decoded = decodeJwt(tokenValue);

  client.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      isLoggedIn: !!decoded,
    },
  });
  client.writeQuery({
    query: ALL_HEADER_DATA,
    data: {
      currentUserName: decoded ? decoded.identity.userName : null,
    },
  });
  return client;
};
export default createClient;
