import React, { useState } from "react";
import { Row, Col, Breadcrumb, message, Input } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { Spinner, GetDataError } from "../../components/common";
import ProductNav from "./components/ProductNav";
import ProductTopNavSelected from "./components/ProductTopNavSelected";
import ProductTable from "./components/ProductTable";
import ProductCategory from "./components/ProductCategory";
import ProductModal from "./components/ProductModal";
import { OperationType } from "../../commons/models";
import {
  CATEGORIES,
  CREATE_CATEGORY_MUTATION,
  CREATE_SUBCATEGORY_MUTATION,
  UPDATE_CATEGORY_MUTATION,
  UPDATE_SUBCATEGORY_MUTATION,
} from "../../graphql/product";

export const ProductPage: React.FC = () => {
  /* category related */
  const [selectedCategoryId, setSelectedCategoryId] = useState<null | number>(); // 当前选中的品类

  /* subcategory related */
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<
    null | number
  >();

  /* product related */
  const [updateProductTable, setUpdateProductTable] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [activeProduct, setActiveProduct] = useState(); // 当前正在更改的商品
  const [createProductModalVisible, setCreateProductModalVisible] = useState(
    false
  );

  /* mutations */
  const [createCategoryMutation] = useMutation(CREATE_CATEGORY_MUTATION);
  const [updateCategoryMutation] = useMutation(UPDATE_CATEGORY_MUTATION);
  const [createSubcategoryMutation] = useMutation(CREATE_SUBCATEGORY_MUTATION);
  const [updateSubcategoryMutation] = useMutation(UPDATE_SUBCATEGORY_MUTATION);

  const { data, loading, error, refetch } = useQuery(CATEGORIES, {
    // variables: {
    //     companyId: companyId
    // }
  });

  if (error) return <GetDataError />;

  if (loading) return <Spinner />;

  function onCategoryChange(key) {
    setSelectedSubcategoryId(null);
    setSelectedCategoryId(key);
  }

  function onCategorySave(itemToSave) {
    if (itemToSave.operationType === OperationType.Add) {
      createCategoryMutation({
        variables: {
          categoryName: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              createCategory: { ok, categoryId },
            },
          }
        ) {
          message.success("新建品类成功");
          refetch();
        },
      });
    } else if (itemToSave.operationType === OperationType.Edit) {
      updateCategoryMutation({
        variables: {
          categoryId: itemToSave.item.id,
          categoryName: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              updateCategory: { ok },
            },
          }
        ) {
          message.success("修改品类成功");
          refetch();
        },
      });
    }
  }

  function onSubcategorySave(itemToSave) {
    if (itemToSave.operationType === 0) {
      createSubcategoryMutation({
        variables: {
          subcategoryName: itemToSave.item.name,
          categoryId: selectedCategoryId,
        },
        update(
          cache,
          {
            data: {
              createSubcategory: { ok, categoryId },
            },
          }
        ) {
          message.success("新建子类成功");
          refetch();
        },
      });
    } else if (itemToSave.operationType === 1) {
      updateSubcategoryMutation({
        variables: {
          subcategoryId: itemToSave.item.id,
          subcategoryName: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              updateSubcategory: { ok },
            },
          }
        ) {
          message.success("修改子类成功");
          refetch();
        },
      });
    }
  }

  return (
    <>
      <div className="c0">
        <Breadcrumb>
          <Breadcrumb.Item>商品库</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>商品库管理</h2>
        <Row>
          <Col span={12} offset={12}>
            <Input.Search
              placeholder="请输入商品名"
              onSearch={(value) => console.log(value)}
              style={{ position: "relative", top: -16, maxWidth: 400 }}
              enterButton
            />
          </Col>
        </Row>
        {/* Categories */}
        <Row align="middle">
          <Col flex="60px">品类：</Col>
          <Col flex="auto">
            <ProductCategory
              categoryItems={data.productCategories}
              onCategorySelect={onCategoryChange}
              onSave={(itemToSave) => {
                onCategorySave(itemToSave);
              }}
              modalTitle="编辑品类"
            />
          </Col>
        </Row>
      </div>
      <div className="c1">
        {/* Subcategories */}
        <Row align="middle">
          <Col flex="60px">子类：</Col>
          <Col flex="auto">
            <ProductCategory
              categoryItems={
                data && selectedCategoryId
                  ? data.productCategories.filter(
                      (cat) => cat.id === selectedCategoryId
                    )[0].subcategories
                  : []
              }
              onCategorySelect={setSelectedSubcategoryId}
              onSave={(itemToSave) => {
                onSubcategorySave(itemToSave);
              }}
              modalTitle="编辑子类"
            />
          </Col>
        </Row>
      </div>
      <div className="c1">
        {/* Products */}
        <ProductNav
          visible={selectedProductIds.length === 0}
          setCreateProductModalVisible={setCreateProductModalVisible}
        />
        {/* 选中商品操作 */}
        <ProductTopNavSelected
          visible={selectedProductIds.length > 0}
          categories={data.productCategories}
          selectedProductIds={selectedProductIds}
          setSelectedProductIds={setSelectedProductIds}
          updateProductTable={updateProductTable}
          setUpdateProductTable={setUpdateProductTable}
        />
        <ProductTable
          selectedCategoryId={selectedCategoryId}
          selectedSubcategoryId={selectedSubcategoryId}
          selectedStatus={selectedStatus}
          selectedProductIds={selectedProductIds}
          setSelectedProductIds={setSelectedProductIds}
          updateProductTable={updateProductTable}
          setActiveProduct={setActiveProduct}
          setCreateProductModalVisible={setCreateProductModalVisible}
        />
      </div>
      {/* 添加/修改 商品浮窗 (modal) */}
      <ProductModal
        visible={createProductModalVisible}
        setVisible={setCreateProductModalVisible}
        categories={data.productCategories}
        selectedCategoryId={selectedCategoryId}
        selectedSubcategoryId={selectedSubcategoryId}
        product={activeProduct}
        setActiveProduct={setActiveProduct}
        updateProductTable={updateProductTable} // 外界告诉里面的table需要update了
        setUpdateProductTable={setUpdateProductTable}
      />
    </>
  );
};

