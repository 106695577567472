import { EmployeesPage } from "./index";
import AppLayout from "../../../components/_layout";


const employeeRoutes = {
  path: "/employees",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: EmployeesPage,
    },
  ],
};

export default employeeRoutes;
