import gql from "graphql-tag";

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    isLoggedIn @client
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($userName: String!, $password: String!) {
    login(password: $password, userName: $userName) {
      ok
      jwt
      error
      myCompanies {
        id
        name
      }
      currentCompanyId
    }
  }
`;

export const LOGIN_CAPTCHA_MUTATION = gql`
  mutation LoginWithCaptcha($phone: String!, $captcha: String!) {
    loginWithCaptcha(captcha: $captcha, phone: $phone) {
      ok
      jwt
      error
      currentCompanyId
    }
  }
`;

export const SEND_CAPTHCHASMS = gql`
  mutation SendCapthchaSms($phone: String!) {
    sendCapthchaSms(phone: $phone) {
      ok
      message
    }
  }
`;
