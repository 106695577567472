import React, { useState, useEffect } from "react";
import { Card, InputNumber, Pagination, Typography, Row, Col } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CARTS,
  ADD_TO_CART,
  REMOVE_CART_ITEMS,
} from "../../../graphql/cart";
import { MALL_PRODUCTS } from "../../../graphql/mall";
import { Spinner, GetDataError } from "../../../components/common";
import logo from "../../../images/logo_square.png";

const { Meta } = Card;
const { Paragraph } = Typography;

const MallCardView = ({
  selectedSupplierId,
  selectedCategoryId,
  selectedSubcategoryId,
  priceGroupId,
  cartItems,
  pagination,
  setPagination,
}) => {
  const [addToCartMutation] = useMutation(ADD_TO_CART);
  const [removeCartItems] = useMutation(REMOVE_CART_ITEMS);

  const { data, loading, error, refetch } = useQuery(MALL_PRODUCTS, {
    variables: {
      ...pagination,
      supplierId: selectedSupplierId,
      categoryId: selectedCategoryId,
      subcategoryId: selectedSubcategoryId,
    },
  });

  // We have to use cartItemsMutable that will be updated through useEffect when cartItems are updated.
  // This is a good solution to get the quantities in this page updated when they are updated by the Carts.tsx in drawer.
  const [cartItemsMutable, setCartItemsMutable] = useState<Array<any> | null>(
    Array<any>()
  );

  useEffect(() => {
    if (data && data.mallProducts) {
      setPagination({ ...pagination, total: data.mallProducts.total });
    }
  }, [data]);

  useEffect(() => {
    setCartItemsMutable(cartItems);
  }, [cartItems]);

  if (loading) return <Spinner />;
  if (error) return <GetDataError />;

  const handlePagerChange = (page) => {
    setPagination({ ...pagination, current: page });
  };

  const handleQuantityChange = (product, qty) => {
    let newCartItems;
    if (
      !cartItemsMutable ||
      !cartItemsMutable.length ||
      cartItemsMutable.findIndex(
        (cartItem) => cartItem.product.id === product.id
      ) === -1
    ) {
      newCartItems = [
        ...(cartItemsMutable ? cartItemsMutable : []),
        {
          product: product,
          qty: qty,
          timeout: setTimeout(() => {
            addToCart(product, qty);
          }, 1000),
        },
      ];
    } else {
      newCartItems = cartItemsMutable.map((cartItem) => {
        if (cartItem.product.id === product.id && cartItem.timeout) {
          clearTimeout(cartItem.timeout);
        }
        return cartItem.product.id === product.id
          ? {
              ...cartItem,
              qty: qty,
              timeout: setTimeout(() => {
                addToCart(product, qty);
              }, 1000),
            }
          : cartItem;
      });
    }

    setCartItemsMutable(newCartItems);
  };

  const addToCart = (product, qty) => {
    if (qty > 0) {
      addToCartMutation({
        variables: {
          cartItem: {
            supplierCompanyId: product.companyId, // 卖家的company_id
            productId: product.id,
            qty: qty,
          },
        },
        refetchQueries: [{ query: GET_CARTS }],
      });
    } else if (qty === 0 && cartItemsMutable) {
      const cartItem = cartItemsMutable.find(
        (item) => item.product.id === product.id
      );
      if (cartItem) {
        removeCartItems({
          variables: {
            ids: [cartItem.id],
          },
          refetchQueries: [{ query: GET_CARTS }],
        });
      }
    }
  };

  const cardStyle = {
    // width: '400px',
    margin: "20px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #e8e8e8",
  };

  const rows = new Array();
  let cols = new Array();
  data.mallProducts.items.forEach((product, i) => {
    const cartItem = cartItemsMutable
      ? cartItemsMutable.find((cartItem) => cartItem.product.id === product.id)
      : null; // see if 当前的 productSpec 已经在购物车里

    cols.push(
      <Col key={i} sm={24} lg={12} xl={8}>
        <Card
          style={cardStyle}
          actions={[
            <div style={{ marginTop: 5 }}>
              {product.customPrice || product.unitPrice} 元 / {product.unit}
            </div>,
            <span>
              <InputNumber
                value={cartItem ? cartItem.qty : 0}
                min={0}
                onChange={(val) => {
                  if (typeof val === "number") {
                    handleQuantityChange(product, val);
                  }
                }}
              />
              <span style={{ marginLeft: 10 }}>{product.unit}</span>
            </span>,
          ]}
        >
          <Meta
            avatar={
              <img
                alt=""
                style={{ width: "64px", height: "64px", borderRadius: "32px" }}
                src={
                  product.imgs && product.imgs.length > 0
                    ? product.imgs[0].url
                    : logo
                }
              />
            }
            title={
              <>
                <div style={{ float: "left" }}>{product.name}</div>
                <div style={{ float: "right", fontSize: "12px" }}>
                  {product.orderCount}次
                </div>
              </>
            }
            description={
              <Paragraph ellipsis={{ rows: 1, expandable: true }}>
                {product.desc}
              </Paragraph>
            }
          />
        </Card>
      </Col>
    );
  });
  rows.push(<Row key="row">{cols}</Row>);

  return (
    <div>
      {rows}
      <Pagination
        {...pagination}
        onChange={handlePagerChange}
        style={{ marginLeft: 20, marginTop: 10 }}
      ></Pagination>
    </div>
  );
};

export default MallCardView;
