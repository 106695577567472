import React from "react";
import loginRoutes from "./pages/login/routes";
import orderRoutes from "./pages/order/routes";
import productRoutes from "./pages/product/routes";
import mallRoutes from "./pages/mall/routes";
import buyerRoutes from "./pages/settings/buyers/routes";
import supplierRoutes from "./pages/settings/suppliers/routes";
import employeeRoutes from "./pages/settings/employees/routes";
import piaozhengRoutes from "./pages/tracking/piaozheng/routes";
import zhengzhaoRoutes from "./pages/tracking/zhengzhao/routes";
import taizhangRoutes from "./pages/tracking/taizhang/routes";
import AppLayout from "./components/_layout";

const route404 = {
  path: "*",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: () => <div>Uh oh.. Page not found</div>,
    },
  ],
};

const routes = [
  {
    path: "/",
    childRoutes: [
      loginRoutes,
      productRoutes,
      mallRoutes,
      orderRoutes,
      buyerRoutes,
      supplierRoutes,
      employeeRoutes,
      piaozhengRoutes,
      zhengzhaoRoutes,
      taizhangRoutes,
      route404,
    ],
  },
];

export default routes;
