import React, { useState, useEffect } from "react";
import { Button, Switch, Table, Typography, message, Divider } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import {
  PRODUCT_PAGE,
  BULK_SET_IN_STOCK_MUTATION,
  DELETE_PRODUCT_MUTATION,
} from "../../../graphql/product";
import { Spinner, GetDataError } from "../../../components/common";

const { Text } = Typography;

const ProductTable = ({
  selectedCategoryId,
  selectedSubcategoryId,
  selectedStatus,
  selectedProductIds,
  setSelectedProductIds,
  updateProductTable,
  setActiveProduct,
  setCreateProductModalVisible,
}) => {
  const localStorageCompanyId = localStorage.getItem("currentCompanyId");
  const [pager, setPager] = useState({ current: 1, pageSize: 10 });
  const [setInStockMutation] = useMutation(BULK_SET_IN_STOCK_MUTATION);
  const [deleteMutation] = useMutation(DELETE_PRODUCT_MUTATION);

  useEffect(() => {
    refetch();
  }, [updateProductTable, selectedCategoryId]);

  const { data, loading, error, refetch } = useQuery(PRODUCT_PAGE, {
    variables: {
      ...pager,
      companyId: localStorageCompanyId,
      categoryId: selectedCategoryId,
    },
  });

  if (error) {
    return <GetDataError />;
  }
  if (loading) {
    return <Spinner />;
  }

  const pagination = { ...pager, total: data.productPage.total };

  const setInStock = (checked, e, product) => {
    setInStockMutation({
      variables: {
        productIds: [product.id],
        inStock: checked,
      },
      update(
        cache,
        {
          data: {
            bulkSetInStock: { ok },
          },
        }
      ) {
        if (ok) {
          if (checked) {
            message.success("上架成功");
          } else {
            message.success("下架成功");
          }
          refetch();
          // setUpdateProductTable(updateProductTable + 1);
        } else {
          message.error("操作失败");
        }
      },
    });
  };

  const handleDelete = (product) => {
    deleteMutation({
      variables: {
        productId: product.id,
      },
      update(cache, { data }) {
        if (data.deleteProduct.ok) {
          message.success("删除成功!");
          refetch();
        } else {
          message.error(data.inviteCompany.message);
        }
      },
    });
  };

  const columns = [
    {
      title: "商品名称",
      dataIndex: "name",
    },
    {
      title: "单位",
      dataIndex: "unit",
    },
    // {
    //   title: "成本价",
    //   align: "center",
    //   render: (product) =>
    //     product.p0s.length > 0 ? (
    //       <span>{product.p0s[0].p0.unitPrice} 元</span>
    //     ) : null,
    // },
    // {
    //   title: "销售价",
    //   align: "center",
    //   render: (product) => <span>{product.unitPrice} 元</span>,
    // },
    // {
    //   title: "供应商",
    //   align: "center",
    //   render: (product) =>
    //     product.p0s.length > 0 ? (
    //       <span>{product.p0s[0].p0.company.name}</span>
    //     ) : (
    //       <div>我</div>
    //     ),
    // },
    {
      title: "状态",
      // align: "center",
      render: (product) => (
        <Switch
          checkedChildren="上架"
          unCheckedChildren="下架"
          checked={product.productStatus == "AVAILABLE"}
          onClick={(checked, e) => setInStock(checked, e, product)}
        />
      ),
      // dataIndex: "productStatus",
    },
    {
      title: "操作",
      render: (product) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setActiveProduct(product);
              setCreateProductModalVisible(true);
            }}
          >
            修改
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            size="small"
            onClick={() => handleDelete(product)}
          >
            <Text type="danger">删除</Text>
          </Button>
        </>
      ),
    },
  ];

  const handlePagerChange = (pager) => {
    setPager({ ...pager });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // Callback executed when selected rows change
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      let selectedProductIds = selectedRows.map((row) => row.id);
      // call parent component's handler to update parent's state
      setSelectedProductIds(selectedProductIds);
    },

    getCheckboxProps: (record) => ({
      disabled: record.status === "deleted", // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedProductIds,
  };

  let filtered_products = data.productPage.items
    .filter(
      (product) =>
        product.categoryId == selectedCategoryId || !selectedCategoryId
    )
    .filter(
      (product) =>
        product.subcategoryId == selectedSubcategoryId || !selectedSubcategoryId
    )
    .filter((product) => product.status == selectedStatus || !selectedStatus);

  return (
    <Table
      dataSource={filtered_products}
      columns={columns}
      rowKey={(product) => product.id}
      rowSelection={rowSelection}
      onChange={handlePagerChange}
      pagination={pagination}
    />
  );
};

export default ProductTable;
