import React, { useState } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const ProductNav = ({ visible, setCreateProductModalVisible }) => {
  return (
    <div style={{ display: visible ? "block" : "none", marginBottom: 20 }}>
      {/* 导出、导入、新添 */}

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setCreateProductModalVisible(true)}
      >
        商品
      </Button>

      <Button type="default" style={{ marginLeft: 20 }}>
        <Link to="/products/bulk">批量添加商品</Link>
      </Button>

      <Button type="default" style={{ marginLeft: 20 }}>
        <Link to="/products/manage_price_groups">价格管理</Link>
      </Button>

      {/* 别删以下comment掉的代码，有参考价值 */}
      {/* <ButtonGroup>
      <Button type="default"
          style={{ marginLeft: 20 }}
          onClick={() => handleExport()}>下载商品Excel</Button>
      <span {...getRootProps()}>
          <input {...getInputProps()} />
          <Button type="default">上传商品Excel</Button>
      </span >
  </ButtonGroup> */}
    </div>
  );
};

export default ProductNav;
