import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Button, Space, Breadcrumb } from "antd";
import IconFont from "../../components/iconfont";
import { MALL_PAGE_QUERY } from "../../graphql/mall";
import { GET_CARTS } from "../../graphql/cart";
import MallTableView from "./components/MallTableView";
import MallCardView from "./components/MallCardView";
import CategoriesBar from "./components/CategoriesBar";
import { Spinner, GetDataError } from "../../components/common";

export const MallPage = () => {
  const [viewMode, setViewMode] = useState("table"); // table or card
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [selectedSupplierId, setSelectedSupplierId] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(0);

  const { data, loading, error } = useQuery(MALL_PAGE_QUERY);

  const {
    data: cart_data,
    loading: cart_loading,
    error: cart_error,
  } = useQuery(GET_CARTS);

  useEffect(() => {
    if (data && data.suppliers && data.suppliers.length > 0) {
      setSelectedSupplierId(data.suppliers[0].id);
    }
  }, [data]);

  if (loading || cart_loading) return <Spinner />;
  if (error || cart_error) return <GetDataError />;

  if (data.suppliers.length === 0) return <div>你还没有添加供应商</div>;

  function onSupplierChange(key) {
    setSelectedSupplierId(key);
    setSelectedCategoryId(0);
    setSelectedSubcategoryId(0);
    console.log(
      data.suppliers.filter((supplier) => supplier.id === selectedSupplierId)
    );
    // setSelectedSupplier(data.suppliers.filter(supplier => supplier.id == selectedSupplierId))
  }

  function toggleViewMode(newViewMode) {
    setViewMode(newViewMode);
    if (newViewMode === "table") {
      setPagination({ current: 1, pageSize: 10 });
    } else if (newViewMode === "card") {
      setPagination({ current: 1, pageSize: 9 });
    }
    setSelectedCategoryId(0);
    setSelectedSubcategoryId(0);
  }
  const cart_of_supplier_id = cart_data.getCarts.filter(
    (cart) => cart.supplierId === selectedSupplierId
  )[0];

  const selectedSupplier = data.suppliers.filter(
    (supplier) => supplier.id === selectedSupplierId
  )[0];

  return (
    <>
      <div className="c0 pb-0">
        <Breadcrumb>
          <Breadcrumb.Item>商城</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>采购商品</h2>
        {data.suppliers.map((supplier) => {
          return (
            <Button
              key={supplier.id}
              type={selectedSupplierId === supplier.id ? "link" : "text"}
              className="tab-style"
              onClick={() => onSupplierChange(supplier.id)}
            >
              {supplier.name}
            </Button>
          );
        })}
      </div>
      <div className="c1">
        <CategoriesBar
          supplier={selectedSupplier}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
          selectedSubcategoryId={selectedSubcategoryId}
          setSelectedSubcategoryId={setSelectedSubcategoryId}
        />
      </div>
      <div className="c1">
        <div className="text-right">
          <Space style={{ fontSize: "1.4em" }}>
            <IconFont
              type="iconliebiaomoshi_kuai"
              className="view-mode-icon"
              onClick={() => toggleViewMode("table")}
            />

            <IconFont
              type="iconshangping"
              className="view-mode-icon"
              style={{}}
              onClick={() => toggleViewMode("card")}
            />
          </Space>
        </div>
        {viewMode === "table" && (
          <MallTableView
            selectedSupplierId={selectedSupplierId}
            selectedCategoryId={selectedCategoryId}
            selectedSubcategoryId={selectedSubcategoryId}
            priceGroupId={
              selectedSupplier ? selectedSupplier.priceGroupId : null
            }
            cartItems={
              cart_of_supplier_id ? cart_of_supplier_id.cartItems : null
            }
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
        {viewMode === "card" && (
          <MallCardView
            selectedSupplierId={selectedSupplierId}
            selectedCategoryId={selectedCategoryId}
            selectedSubcategoryId={selectedSubcategoryId}
            priceGroupId={
              selectedSupplier ? selectedSupplier.priceGroupId : null
            }
            cartItems={
              cart_of_supplier_id ? cart_of_supplier_id.cartItems : null
            }
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
      </div>
    </>
  );
};
