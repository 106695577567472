import gql from "graphql-tag";

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    currentUserName: String!
  }

  extend type Mutation {
    logout: Boolean!
  }
`;

export default typeDefs;
