import React, { useState } from "react";
import { Button, Table } from "antd";
import { useQuery } from "@apollo/client";
import { TAIZHANG_PAGE } from "../../../graphql/order";
import { Spinner, GetDataError } from "../../../components/common";
import moment from "moment";

const TaizhangDateTable = ({
  startDate,
  endDate,
  setDrawerVisible,
  setSelectedDate,
}) => {
  const currentCompanyId = localStorage.getItem("currentCompanyId");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const { data, loading, error, refetch } = useQuery(TAIZHANG_PAGE, {
    variables: {
      ...pagination,
      // mode: "buy",
      //   fromCompanyId: currentCompanyId,
      //   startDate: startDate,
      startDate: startDate,
      endDate: endDate,
      // orderItemStatus: orderItemStatus,
    },
  });

  if (loading) return <Spinner />;
  if (error) return <GetDataError />;
  console.log("DATATTTTT", data);
  const handlePagerChange = (pager) => {
    setPagination({ ...pager });
  };

  const columns = [
    {
      title: "日期",
      dataIndex: "date",
    },
    {
      title: "操作",
      render: (row) => (
        <Button
          onClick={() => {
            setDrawerVisible(true);
            setSelectedDate(row.date);
          }}
        >
          查看
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        dataSource={data.taizhangPage.items}
        columns={columns}
        rowKey={(order_date) => order_date.date}
        pagination={pagination}
        onChange={handlePagerChange}
        locale={{
          emptyText: "没有台账",
        }}
      />
    </div>
  );
};

export default TaizhangDateTable;
