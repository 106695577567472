import React, { useState, useEffect, useContext } from "react";
import {
  ButtonWithDrawerContext,
  CallbackData,
  CallbackStatus,
} from "../../../../components/ButtonWithDrawer";
import {
  Row,
  Col,
  Card,
  Switch,
  InputNumber,
  Slider,
  Space,
  Button,
} from "antd";

import { MyCompany } from "../../../../commons/models";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_RELATION } from "../../../../graphql/company";

const SetSupplierPermission = ({
  initData, //supplier
  callback,
}: {
  initData?: any;
  callback?: (data: any) => void;
}) => {
  console.log("INITD", initData);
  const context = useContext(ButtonWithDrawerContext);
  const currentCompanyId = localStorage.getItem("currentCompanyId");
  const [updateCompanyRelation] = useMutation(UPDATE_COMPANY_RELATION);

  const [data, setData] = useState<any>(initData);

  function startCallback() {
    if (callback) {
      let callbackData: CallbackData;
      let validationMsg = Array<string>();
      //   if (!data) {
      //     validationMsg.push("请输入数据");
      //   } else {
      //     if (!data.date) {
      //       validationMsg.push("请选择日期");
      //     }
      //     if (!data.licenseType) {
      //       validationMsg.push("请添加证照类型");
      //     }
      //   }
      if (validationMsg.length) {
        callbackData = {
          status: CallbackStatus.Warning,
          message: validationMsg,
        };
        callback(callbackData);
      } else {
        updateCompanyRelation({
          variables: {
            companyRelationInput: {
              buyerId: currentCompanyId,
              supplierId: initData.id,
              // allowModifyOrderQty: 1,
              allowModifyOrderPrice: data.allowModifyOrderPrice,
              // allowAutoConfirmOrder: 1,
              allowAutoDeliverOrder: data.allowAutoDeliverOrder,
            },
          },
          update(
            cache,
            {
              data: {
                updateCompanyRelation: { ok },
              },
            }
          ) {
            if (ok) {
              console.log("Got OK from the server");
              callback &&
                callback({ status: CallbackStatus.Success });
            } else {
              console.log("Something went wrong");
              callback &&
                callback({ status: CallbackStatus.Error });
            }
          },
        });
      }
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && data && data !== initData) {
      startCallback();
    }
    return () => {
      isSubscribed = false;
    };
  }, [context]);

  const [deliverDays, setDeliverDays] = useState(1); //todo: remove it. Use data.deliverDays instead in future

  function onAutoDeliverOrderChange(checked) {
    console.log(`switch to ${checked}`, typeof checked);
    setData({ ...data, allowAutoDeliverOrder: checked });
  }
  function onModifyOrderPriceChange(checked) {
    console.log(`switch to ${checked}`);
    setData({ ...data, allowModifyOrderPrice: checked });
  }
  function onDeliverDaysChange(value) {
    console.log(`slide to ${value}`);
    // setData({ ...data, deliverDays: value }); //todo: add it later when model has this prop
    setDeliverDays(value);
  }

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="start">
          <Col flex="100px">
            <b>联系地址：</b>
          </Col>
          <Col flex="auto">{initData.address}</Col>
        </Row>
        <Row justify="start">
          <Col flex="100px">
            <b>联系人：</b>
          </Col>
          <Col flex="auto">{initData.contact}</Col>
        </Row>
        <Row justify="start">
          <Col flex="100px">
            <b>联系方式：</b>
          </Col>
          <Col flex="auto">{initData.contactPhone}</Col>
        </Row>
        <Row justify="start">
          <Col flex="100px">
            <b>查看证照：</b>
          </Col>
          <Col flex="auto">afdasfd</Col>
        </Row>
        <Row>
          <Col flex="0 1 100px">
            <b>供应商权限：</b>
          </Col>
          <Col flex="1 1 400px">
            <Card style={{ width: "100%" }}>
              <Row className="my-2" justify="space-between">
                <Col flex="100px">
                  <b>自动收货</b>
                </Col>
                <Col flex="1 1 300px" className="text-right">
                  <Switch
                    checked={data.allowAutoDeliverOrder}
                    onChange={onAutoDeliverOrderChange}
                  />
                </Col>
              </Row>
              <Row className="my-2" align="middle">
                <Col span={3}>
                  <b>收货时间：</b>
                </Col>
                <Col span={14}>
                  <Slider
                    min={0}
                    max={20}
                    onChange={onDeliverDaysChange}
                    value={typeof deliverDays === "number" ? deliverDays : 0}
                  />
                </Col>
                <Col span={1}>
                  <InputNumber
                    min={0}
                    max={20}
                    style={{ margin: "0 16px" }}
                    value={deliverDays}
                    onChange={onDeliverDaysChange}
                  />
                </Col>
              </Row>
              <Row className="my-2" justify="space-between">
                <Col flex="100px">
                  <b>订单改价</b>
                </Col>
                <Col flex="auto" className="text-right">
                  <Switch
                    checked={data.allowModifyOrderPrice}
                    onChange={onModifyOrderPriceChange}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default SetSupplierPermission;
