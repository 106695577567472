import React, { useState, useEffect } from "react";
import { Button, Card, InputNumber, Empty, message, Table } from "antd";
import { GET_CARTS, SUBMIT_CARTS, ADD_TO_CART } from "../../graphql/cart";
import { useQuery, useMutation } from "@apollo/client";
// import useReactRouter from "use-react-router";
import { createBrowserHistory } from "history";
import { GetDataError, Spinner } from "../common";
// import "./index.scss";

const Carts: React.FC<{
  carts: Array<any> | null;
  callback: (itemIdToDelete: Array<number>) => void; // It has two functions: 1. Notify header carts has been updated; 2. Cart item IDs that needs to be deleted
}> = ({ carts, callback }) => {
  const [cartsMutable, setCartsMutable] = useState<Array<any> | null>(carts);
  const [addToCartMutation] = useMutation(ADD_TO_CART);
  const [submitCartsMutation] = useMutation(SUBMIT_CARTS);

  useEffect(() => {
    setCartsMutable(carts);
  }, [carts]);

  if (!carts || !carts.length) return <Empty description="购物车是空的" />;
  console.log("carts", carts);

  const history = createBrowserHistory();
  // const [selectedCartItemIds, setSelectedCartItemIds] = useState([]);

  const handleQuantityChange = (supplierId, productId, qty) => {
    const updatedCarts =
      cartsMutable &&
      cartsMutable.map((supplier) => {
        return {
          ...supplier,
          cartItems: supplier.cartItems.map((cartItem) => {
            // timer 是加在 cart(Mutable) 的属性。如果有timer 则 reset timer，从而不发送请求给服务器。
            if (
              supplier.supplierId === supplierId &&
              cartItem.product.id === productId &&
              cartItem.timeout
            ) {
              clearTimeout(cartItem.timeout);
            }
            const cartItemWithTimer =
              supplier.supplierId === supplierId &&
              cartItem.product.id === productId
                ? {
                    ...cartItem,
                    qty: qty,
                    timeout: setTimeout(() => {
                      addToCart(supplierId, productId, qty);
                    }, 1000),
                  }
                : cartItem;
            return cartItemWithTimer;
          }),
        };
      });
    setCartsMutable(updatedCarts);
  };

  const addToCart = (supplierId, productId, qty) => {
    addToCartMutation({
      variables: {
        cartItem: {
          supplierCompanyId: supplierId, // 卖家的company_id
          productId: productId,
          qty: qty,
        },
      },
      // refetchQueries: [{ query: GET_CARTS }],
    });

    const cartItemIdsToDelete = Array<any>();
    if (cartsMutable) {
      for (let cart of cartsMutable) {
        for (let cartItem of cart.cartItems) {
          if (
            cartItem.qty === 0 ||
            // Before render, the cartItem.qty might not be 0 although the parameter qty is 0. So next condition will take that into account.
            (cart.supplierId === supplierId &&
              cartItem.product.id === productId &&
              qty === 0)
          ) {
            cartItemIdsToDelete.push(cartItem.id);
          }
        }
      }
    }
    // notify header (the parent component) that the cartItems have been updated, and the list of cartItem IDs that should be deleted.
    callback(cartItemIdsToDelete);
  };

  const submitCart = () => {
    const carts_input = new Array<any>();

    cartsMutable &&
      cartsMutable.forEach((cart) => {
        const item_ids = cart.cartItems.map((cartItem) => cartItem.id);
        carts_input.push({
          cartId: cart.id,
          itemIds: item_ids,
        });
      });
    // carts_input = [{ 'cart_id': cart.id, 'item_ids': [1, 2, 3] }]
    submitCartsMutation({
      variables: {
        carts: carts_input,
      },
      update: (cache, mutationResult) => {
        if (mutationResult.data.submitCarts.ok) {
          message.success("下单成功");
          history.push(`/orders`);
        }
      },
    });
  };

  const columns = [
    {
      title: "商品名称",
      render: (cartItem) => {
        return cartItem.product.name;
      },
    },
    {
      title: "描述",
      render: (row) => {
        return row.product.desc;
      },
    },
    {
      title: "单价",
      render: (cartItem) =>
        (cartItem.customUnitPrice || cartItem.product.unitPrice) +
        " 元/" +
        cartItem.product.unit,
    },
    {
      title: "数量",
      render: (cartItem) => (
        <InputNumber
          /*If using defaultValue, it won't be updated if qty is updated from somewhere else. */
          value={cartItem ? cartItem.qty : 0}
          min={0}
          onChange={(val) => {
            if (typeof val === "number") {
              handleQuantityChange(
                cartItem.cart.supplierId,
                cartItem.product.id,
                val
              );
            }
          }}
        />
      ),
    },
    {
      title: "总金额",
      render: (cartItem) =>
        (cartItem.customUnitPrice || cartItem.product.unitPrice) *
          cartItem.qty +
        " 元",
    },
  ];
  return (
    <div>
      {cartsMutable &&
        cartsMutable.map((cart) => {
          if (cart.cartItems.length > 0) {
            return (
              /* Each supplier gets its own column */
              <Card
                key={cart.supplierId}
                title={<h3 className="text-center">{cart.supplier.name}</h3>}
                size="small"
                bordered={false}
                bodyStyle={{ padding: 0, marginBottom: 32 }}
              >
                <Table
                  dataSource={cart.cartItems}
                  rowKey={(cartItem) => cartItem.id}
                  size="middle"
                  columns={columns}
                  pagination={false}
                />
              </Card>
            );
          } else {
            return <></>;
          }
        })}

      {/* ---- cart footer ---- */}
      <h3
        className="text-right my-2"
        style={{
          borderTop: "1px solid #666",
          margin: "0 -32px",
          padding: "16px 32px",
        }}
      >
        <span className="mx-3">
          总金额:{" "}
          {cartsMutable &&
            cartsMutable
              .reduce((ttl, cart) => {
                return (
                  ttl +
                  cart.cartItems.reduce(
                    (cart_ttl, cartItem) =>
                      cart_ttl +
                      (cartItem.customUnitPrice || cartItem.product.unitPrice) *
                        cartItem.qty,
                    0
                  )
                );
              }, 0)
              .toFixed(2)}{" "}
          元
        </span>
        <Button type="primary" onClick={() => submitCart()}>
          提交订单
        </Button>
      </h3>
    </div>
  );
};

export default Carts;
