import React, { useState } from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  Radio,
  Form,
  DatePicker,
  Select,
  Menu,
  Dropdown,
  Drawer,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import UploadPiaoZhengFromLocal from './components/UploadPiaoZhengFromLocal';
import { ButtonWithDrawer } from '../../../components/ButtonWithDrawer';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ActionBar = ({
  companyId,
  companies,
  categories,
  suppliers,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  update,
  setUpdate,
}) => {
  console.log('companyId', companyId);
  enum PeriodType {
    All,
    Today,
    Yesterday,
    ThisMonth,
  }

  // -- useState ---
  const [period, setPeriod] = useState(PeriodType.All);

  function handleDateRangeChange(dates, dateStrings) {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  }

  function handleSubmit() {
    setUpdate(update + 1);
  }

  return (
    <>
      <Row align="middle" className="my-2">
        <Col span={1}>时间</Col>
        <Col span={8}>
          <Radio.Group
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <Radio.Button
              onClick={() => {
                setStartDate(moment().startOf('year'));
                setEndDate(moment());
              }}
              value={PeriodType.All}
            >
              全年
            </Radio.Button>
            <Radio.Button
              onClick={() => {
                setStartDate(moment());
                setEndDate(moment());
              }}
              value={PeriodType.Today}
            >
              今日
            </Radio.Button>
            <Radio.Button
              onClick={() => {
                setStartDate(moment().subtract(1, 'days'));
                setEndDate(moment());
              }}
              value={PeriodType.Yesterday}
            >
              昨日
            </Radio.Button>
            <Radio.Button
              onClick={() => {
                setStartDate(moment().startOf('month'));
                setEndDate(moment());
              }}
              value={PeriodType.ThisMonth}
            >
              本月
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={8}>
          <RangePicker
            value={[startDate, endDate]}
            onChange={handleDateRangeChange}
          />
        </Col>
        <Col span={7}>
          {/* <Dropdown overlay={dropdownMenu} trigger={["click"]}>
            <Button onClick={(e) => e.preventDefault()} type="primary">
              添加票据 <DownOutlined />
            </Button>
          </Dropdown> 暂时还没有做出来【从供应商导入】功能，所以暂时注释掉了*/}

          <ButtonWithDrawer
            button={<Button type="primary">添加票据</Button>}
            width={600}
            title="从本地添加"
            onConfirm={handleSubmit} // callback B
            maskClosable={true}
            closeOnSuccess={true}
          >
            <UploadPiaoZhengFromLocal
              companyId={companyId}
              piaozheng={null}
              companies={companies}
              categories={categories}
              suppliers={suppliers}
            />
          </ButtonWithDrawer>
        </Col>
      </Row>
    </>
  );
};

export default ActionBar;
