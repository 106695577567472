import React, { useState } from "react";
import { OperationType } from "../../../commons/models";
import { ButtonWithModal } from "../../../components/common";
import KeyValueCollectionEditor from "../../../components/keyvalue-collection-editor/keyvalue-collection-editor";

import { Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";

const ProductCategory = ({
  categoryItems,
  onCategorySelect, // used by parent to handle category select action
  onSave, // used by parent to save the edit/add item, and refresh its children
  modalTitle,
}: {
  categoryItems: any[];
  onCategorySelect: (id: null | number) => void;
  onSave: (item: any, operationType: OperationType) => void;
  modalTitle: string;
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<null | number>(
    0
  );

  function onCategorySelected(categoryId: null | number): void {
    setSelectedCategoryId(categoryId);
    onCategorySelect(categoryId);
  }

  return (
    <div>
      <Space>
        <Button onClick={() => onCategorySelected(null)}>全部</Button>
        {/* list of main categories */}
        {categoryItems.map((item) => (
          <Button
            key={item.id}
            type={item.id === selectedCategoryId ? "primary" : "default"}
            onClick={() => onCategorySelected(item.id)}
          >
            {item.name}
          </Button>
        ))}

        {/* Add button */}
        <ButtonWithModal
          btnType="link"
          btnText="编辑"
          modalTitle={modalTitle}
          btnIcon={<EditOutlined />}
        >
          <KeyValueCollectionEditor items={categoryItems} onSave={onSave} />
        </ButtonWithModal>
      </Space>
    </div>
  );
};

export default ProductCategory;
