import React, { useState } from "react";
import {
  HomeOutlined,
  ShopOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  TableOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

export const MenuLinks = [
  // {
  //   name: "首页",
  //   icon: <HomeOutlined />,
  //   url: "/",
  // },
  // {
  //   name: "商城",
  //   icon: <ShopOutlined />,
  //   url: "/mall",
  // },
    //   {
  //   name: "商品库",
  //   icon: <TableOutlined />,
  //   url: "/products",
  // },

  // {
  //   name: "订单管理",
  //   icon: <ProfileOutlined />,
  //   url: "/orders",
  // },
  {
    name: "商品追溯",
    icon: <DeploymentUnitOutlined />,
     url:"",
    subItems: [
      {
        name: "票据管理",
        url: "/piaozheng",
      },
     {
        name: "证照管理",
        url: "/zhengzhao",
      },
      //  {
      //   name: "电子台账",
      //   url: "/taizhang",
      // },
    ],
  },
  // {
  //   name: "我的",
  //   icon: <UserOutlined />,
  //   url:"",
  //   subItems: [
  //     {
  //       name: "供应商",
  //       url: "/suppliers",
  //     },
  //     {
  //       name: "买家",
  //       url: "/buyers",
  //     },
  //     {
  //       name: "员工",
  //       url: "/employees",
  //     },
  //     {
  //       name: "报表",
  //       url: "/baobiao",
  //     },
  //     {
  //       name: "公司设置",
  //       url: "/company",
  //     },
  //   ],
  // },

];
