import gql from "graphql-tag";

export const COMPANY_FRAGMENT = gql`
  fragment CompanyF on CompanyType {
    id
    name
    districtCode
    address
    createdAt
    contactPhone
  }
`;

export const COMPANY_RELATION_FRAGMENT = gql`
  fragment CompanyRelationF on CompanyRelationType {
    id
    name
    fullName
    contact
    contactPhone
    inviterId
    relationStatus
    relationType
    allowModifyOrderQty
    allowModifyOrderPrice
    allowAutoConfirmOrder
    allowAutoDeliverOrder
  }
`;

export const GET_BUYER_COMPANIES_QUERY = gql`
  query GET_BUYER_COMPANIES_QUERY {
    buyers {
      id
      name
    }
  }
`;

export const GET_SUPPLIER_COMPANIES_QUERY = gql`
  query GET_SUPPLIER_COMPANIES_QUERY {
    suppliers {
      id
      name
    }
  }
`;

export const MY_BUYERS_PAGE = gql`
  query MyBuyersPage {
    myBuyersPage {
      total
      items {
        ...CompanyRelationF
      }
    }
  }
  ${COMPANY_RELATION_FRAGMENT}
`;

export const MY_SUPPLIERS_PAGE = gql`
  query MySuppliersPage {
    mySuppliersPage {
      total
      items {
        ...CompanyRelationF
      }
    }
  }
  ${COMPANY_RELATION_FRAGMENT}
`;

export const COMPANY_INFO_QUERY = gql`
  query COMPANY_INFO_QUERY {
    companyInfo {
      name
      logoUrl
      districtCode
      address
      contact
      contactPhone
      telephone
      tax
      website
      about
    }
  }
`;

export const SEARCH_COMPANY_BY_NAME = gql`
  query SearchCompanyByName($name: String) {
    searchCompanyByName(companyName: $name) {
      id
      name
      fullPath
      address
      fullName
    }
  }
`;

export const SEARCH_NEW_BUYER = gql`
  query SearchNewBuyer($name: String) {
    searchNewBuyer(companyName: $name) {
      id
      name
      fullPath
      address
      fullName
    }
  }
`;
export const SEARCH_NEW_SUPPLIER = gql`
  query SearchNewSupplier($name: String) {
    searchNewSupplier(companyName: $name) {
      id
      name
      fullPath
      address
      fullName
    }
  }
`;

export const SUPPLIER_PRODUCT_TREE = gql`
  query SupplierProductTree {
    mySupplierPage {
      total
      items {
        key: id
        title: name
        children: categories {
          key: id
          title: name
          children: subcategories {
            key: id
            title: name
            children: products {
              key: id
              value: id
              title: name
              unit: unit
            }
          }
        }
      }
    }
  }
`;

export const INVITE_BUYER_CODE = gql`
  query InviteBuyerCode($buyerType: String!) {
    inviteBuyerCode(buyerType: $buyerType) {
      code
    }
  }
`;

export const GET_JOIN_CODE = gql`
  query GetJoinCode($inviteCompanyId: Int!, $code: String!) {
    getJoinCode(inviteCompanyId: $inviteCompanyId, code: $code) {
      inviteCompany {
        name
      }
      buyerType
    }
  }
`;

export const GET_INVITOR_INFO = gql`
  query getInvitorInfo($code: String!, $inviterId: Int!) {
    getInvitorInfo(code: $code, inviterId: $inviterId)
  }
`;

/* Mutations */
export const CREATE_BUYER_COMPANY_MUTATION = gql`
  mutation CREATE_BUYER_COMPANY_MUTATION(
    $companyName: String!
    $userName: String!
    $password: String!
  ) {
    createBuyerCompany(
      companyName: $companyName
      userName: $userName
      password: $password
    ) {
      ok
      companyId
      companyName
    }
  }
`;

export const CREATE_SUPPLIER_COMPANY_MUTATION = gql`
  mutation CREATE_SUPPLIER_COMPANY_MUTATION(
    $companyName: String!
    $userName: String!
    $password: String!
  ) {
    createSupplierCompany(
      companyName: $companyName
      userName: $userName
      password: $password
    ) {
      ok
      companyId
      companyName
    }
  }
`;

export const UPDATE_SUPPLIER_COMPANY_MUTATION = gql`
  mutation UPDATE_SUPPLIER_COMPANY_MUTATION {
    ok
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($companyInfo: CompanyInfoInput!) {
    updateCompany(companyInfo: $companyInfo) {
      ok
      message
    }
  }
`;

export const UPDATE_COMPANY_RELATION = gql`
  mutation UpdateCompanyRelation($companyRelationInput: CompanyRelationInput!) {
    updateCompanyRelation(companyRelationInput: $companyRelationInput) {
      ok
    }
  }
`;

//邀请 创建 商户 供应商
export const INVITE_COMPANY_MUTATION = gql`
  mutation InviteCompany($inputData: InviteCompanyInput!) {
    inviteCompany(inputData: $inputData) {
      ok
      message
    }
  }
`;

export const CREATE_INVITE_COMPANY = gql`
  mutation createInviteCompany($companyName: String!, $relationType: String!) {
    createInviteCompany(
      companyName: $companyName
      relationType: $relationType
    ) {
      ok
      code
    }
  }
`;

export const SWITCH_COMPANY_ACCOUNT_MUTATION = gql`
  mutation switchCompanyAccount($companyId: Int!) {
    switchCompanyAccount(companyId: $companyId) {
      ok
    }
  }
`;

//注册
export const REGISTER_MUTATION = gql`
  mutation Register($inputData: RegisterInput!) {
    register(inputData: $inputData) {
      ok
      message
    }
  }
`;

// 买家 卖家  邀请
export const ADD_BUYER_REQUEST_MUTATION = gql`
  mutation AddBuyerRequest($buyerId: Int!, $relationType: String!) {
    addBuyerRequest(buyerId: $buyerId, relationType: $relationType) {
      ok
    }
  }
`;

export const ADD_SUPPLIER_REQUEST_MUTATION = gql`
  mutation AddSupplierRequest($sellerId: Int!) {
    addSupplierRequest(sellerId: $sellerId) {
      ok
    }
  }
`;

//删除 为通过邀请审核的 供应商
export const REMOVE_UNPASSED_SUPPLIER = gql`
  mutation RemoveUnpassedSupplier($sellerId: Int!) {
    removeUnpassedSupplier(sellerId: $sellerId) {
      ok
    }
  }
`;

//删除 为通过邀请审核的 买家
export const REMOVE_UNPASSED_BUYER = gql`
  mutation RemoveUnpassedBuyer($buyerId: Int!) {
    removeUnpassedBuyer(buyerId: $buyerId) {
      ok
    }
  }
`;

//通过 买家邀请审核
export const PASS_BUYER_REQUEST = gql`
  mutation PassBuyerRequest($buyerId: Int!) {
    passBuyerRequest(buyerId: $buyerId) {
      ok
    }
  }
`;

//加入 其他公司邀请
export const JOIN_INVITE = gql`
  mutation JoinInvite($inviteCompanyId: Int!, $code: String!) {
    joinInvite(inviteCompanyId: $inviteCompanyId, code: $code) {
      ok
    }
  }
`;

//重置公司邀请码
export const RESET_INVITE = gql`
  mutation ResetInvite($buyerType: String!) {
    resetInvite(buyerType: $buyerType) {
      ok
    }
  }
`;

//通过 供应商 邀请审核
export const PASS_SUPPLIER_REQUEST = gql`
  mutation PassSupplierRequest($sellerId: Int!) {
    passSupplierRequest(sellerId: $sellerId) {
      ok
    }
  }
`;
