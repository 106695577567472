import React, { useState } from "react";
import { Button, Divider, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  BULK_DELETE_PRODUCTS_MUTATION,
  BULK_SET_IN_STOCK_MUTATION,
  BULK_UPDATE_PRODUCT_CATEGORY,
} from "../../../graphql/product";
import ProductImportModal from "../bulk/ProductImportModal";

const ProductTopNavSelected = ({
  visible,
  categories,
  selectedProductIds,
  setSelectedProductIds,
  updateProductTable,
  setUpdateProductTable,
}) => {
  const [exportToCategoryId, setExportToCategoryId] = useState<null | number>(); // 把选中的产品导入到这个品类
  const [exportToSubcategoryId, setExportToSubcategoryId] = useState<
    null | number
  >(); // 把选中的产品导入到这个子类

  const [productImportModalVisible, setProductImportModalVisible] = useState(
    false
  );

  const [bulkDeleteProductsMutation] = useMutation(
    BULK_DELETE_PRODUCTS_MUTATION,
    {
      variables: {
        productIds: selectedProductIds,
      },
      update: (
        cache,
        {
          data: {
            bulkDeleteProducts: { ok },
          },
        }
      ) => {
        if (ok) {
          setUpdateProductTable(updateProductTable + 1);
        }
      },
    }
  );

  const [bulkUpdateProductCategoryMutation] = useMutation(
    BULK_UPDATE_PRODUCT_CATEGORY
  );

  const [bulkSetInStockMutation] = useMutation(BULK_SET_IN_STOCK_MUTATION);

  const handleBulkInStock = (inStock) => {
    bulkSetInStockMutation({
      variables: {
        productIds: selectedProductIds,
        inStock: inStock,
      },
      update: (
        cache,
        {
          data: {
            bulkSetInStock: { ok },
          },
        }
      ) => {
        if (ok) {
          setUpdateProductTable(updateProductTable + 1);
        }
      },
    });
  };

  const handleOK = (e) => {
    bulkUpdateProductCategoryMutation({
      variables: {
        productIds: selectedProductIds,
        toCategoryId: exportToCategoryId,
        toSubcategoryId: exportToSubcategoryId,
      },
      update(
        cache,
        {
          data: {
            bulkUpdateProductCategory: { ok },
          },
        }
      ) {
        setProductImportModalVisible(false);
        setSelectedProductIds([]);
        setExportToCategoryId(null);
        setExportToSubcategoryId(null);
        if (ok) {
          message.success(<span>更新成功！</span>);
          setUpdateProductTable(updateProductTable + 1);
        }
      },
    });
  };

  return (
    <div style={{ display: visible ? "block" : "none", marginBottom: 20 }}>
      <span>已选择{selectedProductIds.length}项</span>
      {/* 上架，下架，删除 */}
      {/* <Button
        type="default"
        icon={<CloseOutlined />}
        onClick={() => setSelectedProductIds([])}
      >
        取消
      </Button> */}

      <Button
        type="default"
        // icon="arrow-up"
        style={{ marginLeft: 20 }}
        onClick={() => handleBulkInStock(true)}
      >
        上架
      </Button>

      <Divider type="vertical" />

      <Button
        type="default"
        // icon="arrow-down"
        onClick={() => handleBulkInStock(false)}
      >
        下架
      </Button>

      <Divider type="vertical" />

      <Button
        type="default"
        // icon="arrow-down"
        onClick={() => setProductImportModalVisible(true)}
      >
        移动
      </Button>

      <Divider type="vertical" />

      <Button
        onClick={() => bulkDeleteProductsMutation()}
        style={{
          color: "#fff",
          backgroundColor: "#ff7875",
          borderColor: "#ff7875",
        }}
      >
        删除
      </Button>

      <ProductImportModal
        visible={productImportModalVisible}
        setVisible={setProductImportModalVisible}
        categories={categories}
        exportToCategoryId={exportToCategoryId}
        setExportToCategoryId={setExportToCategoryId}
        exportToSubcategoryId={exportToSubcategoryId}
        setExportToSubcategoryId={setExportToSubcategoryId}
        handleOk={handleOK}
      />
    </div>
  );
};

export default ProductTopNavSelected;
