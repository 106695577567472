import React from "react";

const CartIcon: React.FC<{
  carts: Array<any> | null;
  onClickHandler?: () => void;
}> = ({ children, carts, onClickHandler }) => {
  const cartCount = carts
    ? carts.reduce(
        (cartItemCount, cart) => cartItemCount + cart.cartItems.length,
        0
      )
    : 0;

  return onClickHandler ? (
    <span onClick={onClickHandler} style={{ cursor: "pointer" }}>
      {children}
      {cartCount !== 0 && <span className="badge-for-count">{cartCount}</span>}
    </span>
  ) : (
    <span>
      {children}
      {cartCount !== 0 && <span className="badge-for-count">{cartCount}</span>}
    </span>
  );
};

export default CartIcon;
