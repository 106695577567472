import React from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_MUTATION,
  DELETE_PRODUCT_MUTATION,
} from "../../../graphql/product";
import { Button, Form, Modal } from "antd";
import CreateProductForm from "./ProductForm";
import { useUploadOneLocalImage } from "../../../components/Upload";

// const WrappedProductForm = Form.create({ name: "product_form" })(ProductForm);

const ProductModal = ({
  visible,
  setVisible,
  categories,
  selectedCategoryId,
  selectedSubcategoryId,
  product,
  setActiveProduct,
  updateProductTable,
  setUpdateProductTable,
}) => {
  const [updateProductMutation] = useMutation(UPDATE_PRODUCT_MUTATION);
  const [createProductMutation] = useMutation(CREATE_PRODUCT_MUTATION);

  const [form] = Form.useForm();
  const [UploadLocal, imageLogo, setImage] = useUploadOneLocalImage({
    accept: "image/*",
  });

  const handleSubmit = () => {
    const productMutation = product
      ? updateProductMutation
      : createProductMutation;

    form.validateFields().then((values) => {
      let imgFile;
      if (imageLogo && typeof imageLogo == "string") {
        imgFile = null;
      }
      if (imageLogo && Array.isArray(imageLogo)) {
        imgFile = imageLogo[0];
      }

      productMutation({
        variables: {
          product: {
            id: values.id,
            name: values.productName,
            // supplierId: values.supplierId,
            categoryId: values.categoryId,
            subcategoryId: values.subcategoryId,
            unitPrice: values.unitPrice,
            unit: values.unit,
            customUnit: values.customUnit,
            desc: values.desc,
            img: imgFile
          },
        },
        update(cache, { data }) {
          // debugger;
          // if ((data.createProduct && data.createProduct.ok) || (data.updateProduct && data.updateProduct.ok)) {
          //   history.push(`/products${window.location.search}`); // this triggers a refetch and updates the page
          // }
          setUpdateProductTable(updateProductTable + 1);
          setActiveProduct(null);
          setVisible(false);
        },
        // refetchQueries: [{ query: PRODUCT_PAGE_QUERY, variables: { current: 1 } }]
      });
    });
  };

  const handleCancel = () => {
    setActiveProduct(null);
    setVisible(false);
  };

  const handleDelete = () => {
    // deleteMutation({
    //   variables: {
    //     productId: product.id,
    //   },
    //   update(cache, { data }) {
    //     // console.log(data);
    //     if (data.deleteProduct.ok) {
    //       message.success("删除成功!");
    //       setUpdateProductTable(updateProductTable + 1);
    //     } else {
    //       message.error(data.inviteCompany.message);
    //     }
    //     setVisible(false);
    //   },
    // });
  };

  return (
    <Modal
      title={
        <span style={{ display: "flex", justifyContent: "center" }}>
          {product ? "更新商品" : "创建商品"}
        </span>
      }
      visible={visible}
      destroyOnClose={true}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okText={product ? "更新商品" : "创建商品"}
      cancelText="取消"
      maskClosable={false}
      width={800}
      footer={[
        product ? (
          <Button key="delete" onClick={handleDelete} style={{ float: "left" }}>
            删除商品
          </Button>
        ) : null,
        <Button key="back" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {product ? "更新商品" : "创建商品"}
        </Button>,
      ]}
    >
      <CreateProductForm
        form={form}
        product={product}
        categories={categories}
        selectedCategoryId={selectedCategoryId}
        selectedSubcategoryId={selectedSubcategoryId}
        uploadImage={UploadLocal}
        setImage={setImage}

      />
    </Modal>
  );
};

export default ProductModal;
