import React, { useState } from "react";
import { Button, Modal, Radio, Table, message } from "antd";
import { useMutation } from "@apollo/client";
import OrderDetailHeader from "./OrderDetailHeader";
import ShipModal from "./ShipModal";
import { OrderStatusTypes } from "./order.model";

const OrderButton = ({ mode, orderStatus, porder, order, refetch }) => {
  // 根据 OrderStatusTypes 取 mutation 和 textForNextStatus
  const orderStatusObj = OrderStatusTypes[orderStatus];

  const [currentOrderId, setCurrentOrderId] = useState();
  const [shipStatus, setShipStatus] = useState("TO_DK");
  const [selectShippingModalVisible, setSelectShippingModalVisible] = useState(
    false
  );
  const [updateOrderStatusMutation] = useMutation(orderStatusObj.mutation);

  const updateOrderStatus = (orderId) => {
    updateOrderStatusMutation({
      variables: {
        orderId: orderId,
        shipStatus: shipStatus,
      },
      update: (proxy, { data }) => {
        if (
          (data.confirmOrder && data.confirmOrder.ok) ||
          (data.forwardOrder && data.forwardOrder.ok) ||
          (data.shipOrder && data.shipOrder.ok) ||
          (data.deliverOrder && data.deliverOrder.ok)
        ) {
          message.success("操作成功");
          refetch();
        } else {
          debugger;
        }
      },
    });
  };

  const isDisabled = (porder, order, mode) => {
    console.log("porder", porder, "order", order, ", Mode", mode);
    if (!porder) {
      return false;
    }
    if (mode === "sales") {
      if (porder.toCompanyId == order.toCompanyId) {
        return false;
      }
      return true;
    }
    // debugger
    if (mode === "purchase") {
      if (porder.fromCompanyId == order.shipToCompanyId) {
        return false;
      }
      return true;
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          if (orderStatus === "PENDING_FORWARD") {
            setCurrentOrderId(order.id);
            setSelectShippingModalVisible(true);
          } else {
            updateOrderStatus(order.id);
          }
        }}
        disabled={isDisabled(porder, order, mode)}
      >
        {orderStatusObj.textForNextStatus}
      </Button>

      {/* 选择配送地址 modal */}
      <ShipModal
        mutation={updateOrderStatus}
        orderId={currentOrderId}
        visible={selectShippingModalVisible}
        setVisible={setSelectShippingModalVisible}
        shipStatus={shipStatus}
        setShipStatus={setShipStatus}
      />
    </>
  );
};

export default OrderButton;
