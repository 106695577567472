import React, { useState, useEffect } from "react";
import { TokenContext } from "../../index";
import {
  Form,
  Alert,
  Button,
  Input,
  Spin,
  Layout,
  Divider,
  Row,
  Col,
} from "antd";
import { decodeJwt } from "../../commons/utils";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  LOGIN_MUTATION,
  SEND_CAPTHCHASMS,
  LOGIN_CAPTCHA_MUTATION,
} from "../../graphql/login";
import { useMutation } from "@apollo/client";

const LoginForm = () => {
  const [passwordLogin, setPasswordLogin] = useState(true);
  const [message, setMessage] = useState();
  const [captchaSent, setCaptchaSent] = useState(false);
  const { setToken, setCompanyId } = React.useContext(TokenContext);

  const [loginMutation] = useMutation(LOGIN_MUTATION);
  let history = useHistory();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (passwordLogin) {
      console.log("passwordLogin submit");
    } else {
      //验证码登录
      console.log("验证码登录 submit");
    }
    form.validateFields().then((values) => {
      console.log("values", values);
      loginMutation({
        variables: {
          userName: values.userName,
          password: values.password,
        },
        update(cache, { data: { login } }) {
          // setLoading(false);
          afterLogin(cache, login);
        },
      });
    });
  };

  const afterLogin = (cache, login) => {
    if (login && login.ok === true) {
      localStorage.setItem("token", login.jwt ? login.jwt : "");
      setToken(login.jwt);
      setCompanyId(login.currentCompanyId);
      localStorage.setItem(
        "currentCompanyId",
        login.currentCompanyId ? login.currentCompanyId : 0
      );
      // const decoded = decodeJwt(login.jwt);
      // cache.modify({
      //   isLoggedIn: true,
      //   currentUserName: decoded ? decoded.identity.username : null,
      // });
      history.push("/piaozheng");
    } else {
      setMessage(login.error);
    }
  };

  const sendCaptcha = (e) => {
    console.log("sendCaptcha clicked");
  };

  // <div>
  //   Login Form
  //   <Button
  //     onClick={() => {
  //       localStorage.setItem("token", "aaa");
  //       setToken("aaa");
  //     }}
  //   >
  //     Set Token
  //   </Button>
  // </div>

  return (
    <Layout.Content className="register__content">
      {/* <Spin spinning={loading}> */}
      <Form form={form} className="login_form">
        {message && (
          <Alert
            message={message}
            type="error"
            showIcon
            style={{ width: "100%", marginBottom: 15 }}
          />
        )}

        {passwordLogin ? (
          <Form.Item
            name="userName"
            rules={[{ required: true, message: "请输入用户名或手机号" }]}
          >
            <Input placeholder="用户名或手机号" autoFocus size="large" />
          </Form.Item>
        ) : (
          <Form.Item
            name="userName"
            rules={[{ required: true, message: "请输入手机号!" }]}
          >
            <Input placeholder="手机号" autoFocus size="large" />
          </Form.Item>
        )}

        {passwordLogin ? (
          <Form.Item
            name="password"
            rules={[{ required: true, message: "请输入密码!" }]}
          >
            <Input type="password" placeholder="密码" size="large" />
          </Form.Item>
        ) : (
          <Form.Item
            name="captcha"
            rules={[
              {
                required: true,
                message: "请输入验证码",
              },
            ]}
          >
            <Row>
              <Col xs={{ span: 13, offset: 0 }}>
                <Input size="large" placeholder="验证码" />
              </Col>
              <Col xs={{ span: 9, offset: 1 }}>
                <Button
                  size="large"
                  disabled={captchaSent}
                  onClick={(e) => sendCaptcha(e)}
                  style={{ width: "100%" }}
                >
                  {/* <span
                    className={classnames({ "code-count-text": captchaSent })}
                  >
                    {countDown === 0 ? "发送验证码" : `  ${countDown}秒`}
                  </span> */}
                </Button>
              </Col>
            </Row>

            {captchaSent && (
              <div className="register__content__send">
                <span>验证码已发送至您的手机，请注意查收短信</span>
              </div>
            )}
          </Form.Item>
        )}

        <Form.Item>
          <Button
            onClick={handleSubmit}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ marginRight: "10px", width: "100%" }}
          >
            登录
          </Button>
        </Form.Item>
      </Form>

      <div
        className="login_switch"
        onClick={() => {
          // setMessage('');
          setPasswordLogin(!passwordLogin);
        }}
      >
        {passwordLogin ? (
          <span style={{ marginLeft: 40 }}> 短信验证码登录</span>
        ) : (
          <span style={{ marginLeft: 40 }}> 账号密码登录</span>
        )}
      </div>
      <Divider></Divider>
      <span style={{ marginLeft: 40 }}>
        <Link to="/register">没有账号, 立即注册</Link>
      </span>
    </Layout.Content>
  );
};

export default LoginForm;
