import React, { useState } from "react";
import { Button, Switch, Table, Tag, message, Divider, Breadcrumb } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { EMPLOYEES_PAGE } from "../../../graphql/user";
import { Spinner, GetDataError } from "../../../components/common";
import { ButtonWithDrawer } from "../../../components/ButtonWithDrawer";
import EditRoleDrawer from "./EditRoleDrawer";

export const EmployeesPage: React.FC = () => {
  const [pager, setPager] = useState({ current: 1, pageSize: 10 });
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState();

  const { data, loading, error, refetch } = useQuery(EMPLOYEES_PAGE, {
    variables: {
      ...pager,
    },
  });

  if (error) {
    return <GetDataError />;
  }
  if (loading) {
    return <Spinner />;
  }
  const handlePagerChange = (pager) => {
    setPager({ ...pager });
  };

  const openEditForm = (employee) => {
    setCurrentEmployee(employee);
    setEditDrawerVisible(true);
  };

  const employees = data.employeesPage.items || [];
  const pagination = { ...pager, total: data.employeesPage.total };
  const columns = [
    {
      title: "姓名",
      dataIndex: "realName",
    },
    {
      title: "手机号",
      dataIndex: "phone",
    },
    {
      title: "状态",
      render: (employee) =>
        employee.userStatus === "ENABLED" ? "开通" : "停用",
    },
    {
      title: () => <span className="ml-16px">操作</span>,
      render: (employee) => (
        <>
          <Button onClick={() => openEditForm(employee)}>设置</Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="c0">
        <Breadcrumb>
          <Breadcrumb.Item>我的员工</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>员工管理</h2>
      </div>
      <div className="c1">
        <Table
          dataSource={employees}
          columns={columns}
          rowKey={(role) => `role_${role.id}`}
          loading={loading}
          onChange={handlePagerChange}
          pagination={pagination}
        />
      </div>
      <EditRoleDrawer
        abilityCategories={data.abilityCategories}
        companyRoles={data.companyRoles}
        employee={currentEmployee}
        setCurrentEmployee={setCurrentEmployee}
        visible={editDrawerVisible}
        setVisible={setEditDrawerVisible}
        refetch={refetch}
      />
    </>
  );
};
