import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  Pagination,
  Checkbox,
  Typography,
  Tag,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PreviewApi } from '@zzwing/react-image';
import { PIAOZHENG_PAGE } from '../../../../graphql/piaozheng';
import { Spinner, GetDataError } from '../../../../components/common';
import logo from '../../../../images/logo_square.png';
import { ButtonWithDrawer } from '../../../../components/ButtonWithDrawer';
import UploadPiaoZhengFromLocal from './UploadPiaoZhengFromLocal';
import { convertImage } from '..';
import DeletePiaoButton from './DeletePiaoButton';

const { Meta } = Card;

const PiaozhengCards = ({
  companyId,
  companies,
  categories,
  suppliers,
  selectedCategoryId,
  selectedSupplierCateIds,
  selectedPiaozhengIds,
  setSelectedPiaozhengIds,
  startDate,
  endDate,
  update,
}) => {
  // const localStorageCompanyId = localStorage.getItem("currentCompanyId");
  const [pager, setPager] = useState({ pageNum: 1, pageSize: 9 });

  const { data, loading, error, refetch } = useQuery(PIAOZHENG_PAGE, {
    variables: {
      ...pager,
      companyId: companyId,
      categoryId: selectedCategoryId,
      supplierCateIds: selectedSupplierCateIds,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
    },
  });

  useEffect(() => {
    refetch();
  }, [update]);

  if (error) {
    return <GetDataError />;
  }
  if (loading) {
    return <Spinner />;
  }
  const pagination = { ...pager, total: data.piaozhengPage.total };

  const handlePagerChange = (pageNum, pageSize) => {
    // console.log('pageNum', pageNum, 'pageSize', pageSize);
    setPager({ ...pagination, pageNum: pageNum, pageSize: pageSize });
  };

  const onPiaozhengChecked = (e, id) => {
    console.log('e', e);
    if (e.target.checked) {
      setSelectedPiaozhengIds([...selectedPiaozhengIds, id]);
    } else {
      setSelectedPiaozhengIds(
        selectedPiaozhengIds.filter((i) => i != id),
      );
    }
  };

  function handleSubmit() {
    console.log('handleSubmit');
  }

  const cardStyle = {
    // width: '400px',
    margin: '20px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    border: '1px solid #e8e8e8',
  };

  let rows = Array();
  let cols = Array();
  data.piaozhengPage.items.forEach((piaozheng, i) => {
    const existingImages = convertImage(piaozheng);

    cols.push(
      <Col key={i} sm={24} lg={12} xl={8}>
        <Card
          style={cardStyle}
          actions={[
            <Button
              type="text"
              onClick={() => {
                if (existingImages.length > 0) {
                  PreviewApi.preview(
                    0,
                    existingImages.map((img) => img.preview),
                  );
                }
              }}
            >
              查看
            </Button>,
            <ButtonWithDrawer
              button={<Button type="text">修改</Button>}
              width={600}
              title="查看票据"
              onConfirm={handleSubmit} // callback B
              maskClosable={true}
              closeOnSuccess={true}
            >
              <UploadPiaoZhengFromLocal
                companyId={companyId}
                piaozheng={piaozheng}
                companies={companies}
                categories={categories}
                suppliers={suppliers}
              />
            </ButtonWithDrawer>,
            <DeletePiaoButton
              piaozheng={piaozheng}
              refetch={refetch}
            />,
          ]}
        >
          <Meta
            avatar={
              <img
                alt=""
                style={{
                  width: '64px',
                  height: '64px',
                  borderRadius: '32px',
                }}
                src={
                  existingImages.length > 0
                    ? existingImages[0].preview
                    : logo
                }
              />
            }
            title={
              <>
                <div style={{ float: 'left' }}>{piaozheng.name}</div>
                <div style={{ float: 'right' }}>
                  <Checkbox
                    checked={selectedPiaozhengIds.includes(
                      piaozheng.id,
                    )}
                    onChange={(e) =>
                      onPiaozhengChecked(e, piaozheng.id)
                    }
                  />
                </div>
              </>
            }
            description={
              <>
                <Tag color="default">
                  {
                    companies.find(
                      (company) => company.id === piaozheng.companyId,
                    )?.name
                  }
                </Tag>
                <Tag color="default">{piaozheng.date}</Tag>
              </>
            }
          />
        </Card>
      </Col>,
    );
  });
  rows.push(<Row key="row">{cols}</Row>);

  return (
    <div>
      {rows}
      <Pagination
        {...pagination}
        onChange={handlePagerChange}
        style={{ marginLeft: 20, marginTop: 10 }}
      ></Pagination>
    </div>
  );
};

export default PiaozhengCards;
