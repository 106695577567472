import { ZhengzhaoPage } from "./index";
import AppLayout from "../../../components/_layout";


const zhengzhaoRoutes = {
  path: "/zhengzhao",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: ZhengzhaoPage,
    },
  ],
};

export default zhengzhaoRoutes;