import React from "react";
import { TokenContext } from "../index";

const Logout = () => {
  const { removeToken, removeCompanyId } = React.useContext(TokenContext);
  // const client = useApolloClient();
  const handleLogout = () => {
    removeToken();
    removeCompanyId();
    // client.resetStore();
    // client.writeData({ data: { isLoggedIn: false } });
  };

  return (
    <div onClick={handleLogout} role="presentation">
      {/* <Icon type="logout" /> */}
      <span>退出</span>
    </div>
  );
}
export default Logout;
