import gql from "graphql-tag";
import {
  CATEGORY_FRAGMENT,
  SUBCATEGORY_FRAGMENT,
  PRODUCT_FRAGMENT,
} from "./product";

export const MALL_PAGE_QUERY = gql`
  query MALL_PAGE_QUERY {
    suppliers {
      id
      name
      categories {
        ...Category
        subcategories {
          ...Subcategory
        }
      }
    }
  }
  ${CATEGORY_FRAGMENT}
  ${SUBCATEGORY_FRAGMENT}
`;

export const MALL_PRODUCTS = gql`
  query MallProducts(
    $supplierId: Int
    $categoryId: Int
    $subcategoryId: Int
    $current: Int
    $pageSize: Int
  ) {
    mallProducts(
      supplierId: $supplierId
      categoryId: $categoryId
      subcategoryId: $subcategoryId
      pageNum: $current
      pageSize: $pageSize
    ) {
      total
      items {
        ...Product
        orderCount
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
