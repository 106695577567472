import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  Button,
  Tabs,
  Breadcrumb,
  message,
  Select,
} from 'antd';
import { Spinner, GetDataError } from '../../../components/common';
import PiaozhengCards from './components/PiaozhengCards';
import { OperationType } from '../../../commons/models';
import {
  PIAOZHENG_INDEX_PAGE,
  CREATE_PIAOZHENG_CATEGORY_MUTATION,
  UPDATE_PIAOZHENG_CATEGORY_MUTATION,
  CREATE_SUPPLIER_CATE_MUTATION,
  UPDATE_SUPPLIER_CATE_MUTATION,
} from '../../../graphql/piaozheng';
import PiaozhengCategory from './components/PiaozhengCategory';
import ActionBar from './ActionBar';
import PiaozhengSuppliers from './components/PiaozhengSuppliers';

const { TabPane } = Tabs;
const { Option } = Select;

const ossUrl = 'https://piaozheng.smqsmq.com/';

export function convertImage(piaoju) {
  if (!piaoju || !piaoju.images) return [];
  try {
    const images = JSON.parse(piaoju.images);

    return images.map((image) => {
      return { preview: ossUrl + image };
    });
  } catch (e) {
    console.log('Error JSON.parse()ing piaoju', piaoju);
  }
}

export const PiaozhengPage: React.FC = () => {
  /* 票据类别 related */
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(
    0,
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    null | number
  >(); // 当前选中的票据类别
  const [selectedSupplierCateIds, setSelectedSupplierCateIds] = useState([]);

  /* 票据时间 */
  const [startDate, setStartDate] = useState(
    moment().startOf('year'),
  ); // moment obj
  const [endDate, setEndDate] = useState(moment()); // moment obj

  /* 票据 related */
  const [update, setUpdate] = useState(0);

  const [selectedPiaozhengIds, setSelectedPiaozhengIds] = useState(
    [],
  );

  /* mutations */
  const [createCategoryMutation] = useMutation(
    CREATE_PIAOZHENG_CATEGORY_MUTATION,
  );
  const [updateCategoryMutation] = useMutation(
    UPDATE_PIAOZHENG_CATEGORY_MUTATION,
  );
  const [createSupplierCateMutation] = useMutation(
    CREATE_SUPPLIER_CATE_MUTATION,
  );
  const [updateSupplierCateMutation] = useMutation(
    UPDATE_SUPPLIER_CATE_MUTATION,
  );

  const { data, loading, error, refetch } = useQuery(
    PIAOZHENG_INDEX_PAGE,
  );

  // useEffect(() => {
  //   if (data) {
  //     if (data.myCompany.organization[0]) {
  //       // 有 organization 的话，初始值取第一个公司
  //       setSelectedCompanyId(
  //         data.myCompany.organization[0].companies[0].id,
  //       );
  //     } else {
  //       // 没有 organization 的话，companyId 就是 currentCompanyId
  //       setSelectedCompanyId(data.myCompany.id);
  //     }
  //   }
  // }, [data]);

  if (error) return <GetDataError />;
  if (loading) return <Spinner />;
  console.log('data', data);
  const organizationCompanies =
    data && data.myCompany.organization[0]
      ? data.myCompany.organization[0].companies
      : [data.myCompany];

  function onCategoryChange(key) {
    setSelectedCategoryId(key);
  }

  function onCategorySave(itemToSave) {
    if (itemToSave.operationType == OperationType.Add) {
      createCategoryMutation({
        variables: {
          categoryName: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              createPiaozhengCategory: { ok, categoryId },
            },
          },
        ) {
          message.success('新建品类成功');
          refetch();
        },
      });
    } else if (itemToSave.operationType == OperationType.Edit) {
      updateCategoryMutation({
        variables: {
          categoryId: itemToSave.item.id,
          categoryName: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              updatePiaozhengCategory: { ok },
            },
          },
        ) {
          message.success('修改类别成功');
          refetch();
        },
      });
    }
  }

  function onSupplierCateSave(itemToSave) {
    if (itemToSave.operationType == OperationType.Add) {
      createSupplierCateMutation({
        variables: {
          name: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              createSupplierCate: { ok, categoryId },
            },
          },
        ) {
          message.success('新建品类成功');
          refetch();
        },
      });
    } else if (itemToSave.operationType == OperationType.Edit) {
      updateSupplierCateMutation({
        variables: {
          categoryId: itemToSave.item.id,
          categoryName: itemToSave.item.name,
        },
        update(
          cache,
          {
            data: {
              updateSupplierCate: { ok },
            },
          },
        ) {
          message.success('修改供应商成功');
          refetch();
        },
      });
    }
  }

  return (
    <>
      <div className="c0">
        <Breadcrumb>
          <Breadcrumb.Item>票据管理</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>查看票据</h2>
      </div>

      <div className="c1 pb-0">
        <div className="mb-4">
          <span className="mr-2">按档口查询:</span>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="选择档口"
            optionFilterProp="children"
            defaultValue={0}
            onChange={(companyId) => {
              setSelectedCompanyId(companyId);
            }}
          >
            <Option value={0}>全部</Option>
            {organizationCompanies.map((company) => (
              <Option value={company.id} key={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>
        <Row align="middle">
          <Col>
            <span className="mr-2">按证照类别查询:</span>
          </Col>
          <Col flex="auto">
            <PiaozhengCategory
              categoryItems={data.piaozhengCategories}
              onCategorySelect={onCategoryChange}
              onSave={(itemToSave) => {
                onCategorySave(itemToSave);
              }}
            />
          </Col>
        </Row>
        <Row align="middle" style={{ marginTop: 15 }}>
          <Col>
            <span className="mr-2">按供应商查询:</span>
          </Col>
          <Col>
            <PiaozhengSuppliers
              suppliers={data.mySupplierCatesPage.items}
              selectedSupplierCateIds={selectedSupplierCateIds}
              setSelectedSupplierCateIds={setSelectedSupplierCateIds}
              onSave={(itemToSave) => {
                onSupplierCateSave(itemToSave);
              }}
            />
          </Col>
        </Row>

        <div className="c0">
          <ActionBar
            companyId={selectedCompanyId}
            companies={organizationCompanies}
            categories={data.piaozhengCategories}
            suppliers={data.mySupplierCatesPage.items}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            update={update}
            setUpdate={setUpdate}
          />
        </div>

        <div className="c0">
          <PiaozhengCards
            companyId={selectedCompanyId}
            companies={organizationCompanies}
            categories={data.piaozhengCategories}
            selectedCategoryId={selectedCategoryId}
            suppliers={data.mySupplierCatesPage.items}
            selectedSupplierCateIds={selectedSupplierCateIds}
            selectedPiaozhengIds={selectedPiaozhengIds}
            setSelectedPiaozhengIds={setSelectedPiaozhengIds}
            startDate={startDate}
            endDate={endDate}
            update={update}
          />
        </div>
      </div>
    </>
  );
};
