import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { DELETE_PIAOZHENG_MUTATION } from '../../../../graphql/piaozheng';

const { confirm } = Modal;

const DeletePiaoButton = ({ piaozheng, refetch }) => {
  const [deleteMutation] = useMutation(DELETE_PIAOZHENG_MUTATION);

  function showDeleteConfirm(id) {
    confirm({
      title: '确认要删除此票证吗?',
      //   content: 'Some descriptions',
      okText: '确定删除',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        handleDelete(id);
      },
    });
  }

  function handleDelete(id) {
    console.log('deleting piaozheng.id', id);
    deleteMutation({
      variables: {
        piaozhengId: id,
      },

      update: (cache, mutationResult) => {
        if (mutationResult.data.deletePiaozheng.ok) {
          refetch();
        }
      },
    });
  }

  return (
    <Button
      onClick={(e) => {
        showDeleteConfirm(piaozheng.id);
      }}
      type="text"
    >
      删除
    </Button>
  );
};

export default DeletePiaoButton;
