import React, { useState } from "react";
import { Button, Modal, Radio } from "antd";

const ShipModal = ({
  mutation,
  orderId,
  visible,
  setVisible,
  shipStatus,
  setShipStatus,
}) => {
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <Modal
      title={
        <span style={{ display: "flex", justifyContent: "center" }}>
          请选择配送地址
        </span>
      }
      visible={visible}
      destroyOnClose={true}
      onOk={() => {
        mutation(orderId);
        setVisible(false);
      }}
      onCancel={() => setVisible(false)}
      okText="确认"
      cancelText="取消"
      maskClosable={true}
    >
      <Radio.Group
        onChange={(e) => {
          setShipStatus(e.target.value);
        }}
        value={shipStatus}
      >
        <Radio style={radioStyle} value="TO_DK">
          发货给买家
        </Radio>
        <Radio style={radioStyle} value="TO_DISTRIBUTOR_COMBINE">
          发货给我（不分拣）
        </Radio>
        <Radio style={radioStyle} value="TO_DISTRIBUTOR_SEPARATE">
          发货给我（分拣）
        </Radio>
      </Radio.Group>
    </Modal>
  );
};

export default ShipModal;
