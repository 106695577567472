import React from "react";
import { Modal, Tag } from "antd";

const { CheckableTag } = Tag;

const ProductImportModal = ({
  visible,
  setVisible,
  categories,
  exportToCategoryId,
  setExportToCategoryId,
  exportToSubcategoryId,
  setExportToSubcategoryId,
  handleOk,
}) => {
  return (
    <Modal
      visible={visible}
      title="导入到商品库"
      onOk={handleOk}
      onCancel={() => {
        setVisible(false);
      }}
      okText={
        <span>
           "导入
        </span>
      }
      okButtonProps={{
        disabled: !exportToCategoryId
      }}
      cancelText="取消"
      width={800}
    >
      <div style={{ marginBottom: 20 }}>
        <span style={{ marginRight: 20 }}>请选择品类: </span>
        {categories.map((cat) => {
          return (
            <span key={cat.id}>
              <CheckableTag
                checked={exportToCategoryId && exportToCategoryId == cat.id}
                onChange={() => setExportToCategoryId(cat.id)}
                style={{ marginLeft: 2 }}
              >
                {cat.name}
              </CheckableTag>
            </span>
          );
        })}
      </div>
      {exportToCategoryId && (
        <div style={{ marginBottom: 20 }}>
          <span style={{ marginRight: 20 }}>请选择子类: </span>
          {exportToCategoryId &&
            exportToCategoryId.subcategories.map((sub) => {
              return (
                <span key={sub.id}>
                  <CheckableTag
                    checked={
                      exportToSubcategoryId == sub.id
                    }
                    onChange={() => setExportToSubcategoryId(sub)}
                    style={{ marginLeft: 2 }}
                  >
                    {sub.name}
                  </CheckableTag>
                </span>
              );
            })}
        </div>
      )}
    </Modal>
  );
};

export default ProductImportModal;
