import { SuppliersPage } from "./index";
import AppLayout from "../../../components/_layout";

const supplierRoutes = {
  path: "/suppliers",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: SuppliersPage,
    },
  ],
};

export default supplierRoutes;
