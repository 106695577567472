import React, { useState } from 'react';
import { Button, Space, Tag } from 'antd';
import { ButtonWithModal } from '../../../../components/common';
import { EditOutlined } from '@ant-design/icons';
import KeyValueCollectionEditor from '../../../../components/keyvalue-collection-editor/keyvalue-collection-editor';

const { CheckableTag } = Tag;

const PiaozhengSuppliers = ({
  suppliers,
  selectedSupplierCateIds,
  setSelectedSupplierCateIds,
  onSave,
}) => {
  function handleChange(supplier) {
    console.log('SUPPLIER', supplier);
    const checked = selectedSupplierCateIds.indexOf(supplier.id) === -1;
    // debugger;
    const nextSelectedSupplierCateIds = checked
      ? [...selectedSupplierCateIds, supplier.id]
      : selectedSupplierCateIds.filter((t) => t !== supplier.id);
    setSelectedSupplierCateIds(nextSelectedSupplierCateIds);
  }

  return (
    <div>
      <Space>
        <Button
          type="default"
          onClick={() => setSelectedSupplierCateIds([])}
        >
          全部
        </Button>

        {suppliers.map((supplier) => (
          <Button
            key={supplier.id}
            type={
              selectedSupplierCateIds.indexOf(supplier.id) > -1
                ? 'primary'
                : 'default'
            }
            onClick={(checked) => handleChange(supplier)}
          >
            {supplier.name}
          </Button>
        ))}

        {/* Add button */}
        <ButtonWithModal
          btnType="link"
          btnText="编辑"
          modalTitle="编辑品类"
          btnIcon={<EditOutlined />}
        >
          <KeyValueCollectionEditor
            items={suppliers}
            onSave={onSave}
          />
        </ButtonWithModal>
      </Space>
    </div>
  );
};

export default PiaozhengSuppliers;
