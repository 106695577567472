import { MallPage } from "./index";
import AppLayout from "../../components/_layout";

const mallRoutes = {
  path: "/mall",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: MallPage,
    },
  ],
};
export default mallRoutes;
