import React, { useState } from "react";
import { Alert, Spin, Button, Modal } from "antd";

export const Spinner = () => (
  <div className="my-5 center-x center-y">
    <Spin />
  </div>
);

export const GetDataError = () => (
  <Alert message="错误" description="加载数据失败了" type="error" showIcon />
);

export const SetDataError = () => (
  <Alert message="错误" description="更新数据失败了" type="error" showIcon />
);

export const ButtonWithModal = ({
  children,
  btnType,
  btnText,
  btnIcon,
  modalTitle,
  ...otherProps
}) => {
  const [visible, setVisible] = useState(false);

  function showModal() {
    setVisible(true);
  }

  function handleCancel() {
    setVisible(false);
  }

  return (
    <>
      <Button type={btnType} icon={btnIcon} onClick={showModal} {...otherProps}>
        {btnText}
      </Button>
      <Modal
        title={modalTitle}
        visible={visible}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            返回
          </Button>,
        ]}
      >
        <div style={{ overflowY: "auto", height: "50vh" }}>{children}</div>
      </Modal>
    </>
  );
};
