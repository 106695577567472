import React, { useState, useEffect, useContext } from "react";
import { Alert, Button, Radio, Space, Divider, Row, Col } from "antd";
import {
  CallbackData,
  CallbackStatus,
} from "../../../../components/ButtonWithDrawer";
import { Spinner, GetDataError } from "../../../../components/common";
import { MyCompany } from "../../../../commons/models";

const AddSupplier = ({
  callback,
}: {
  callback?: (data: CallbackData) => void;
}) => {
  // 1: 发送二维码方式， 2：发送链接方式
  const [addOption, setAddOption] = useState(1);

  function onRadioChange(e) {
    setAddOption(e.target.value);
  }

  function generateAndDownloadBarcode(e) {
    e.stopPropagation();
  }

  function test() {
    callback &&
      callback({
        status: CallbackStatus.Success,
        message: ["Data saved."],
        byConfirm: false,
      });
  }

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    opacity: 0.7,
  };
  const radioStyleActive = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    opacity: 1,
  };

  const rowStyle = {
    fontSize: "14px",
    textAlign: "center" as const,
    height: "80px",
    opacity: 0.7,
  };
  const rowStyleActive = {
    fontSize: "14px",
    textAlign: "center" as const,
    height: "80px",
    opacity: 1,
  };

  return (
    <>
      <Radio.Group
        onChange={onRadioChange}
        value={addOption}
        style={{ width: "100%" }}
      >
        <Row style={addOption === 1 ? rowStyleActive : rowStyle} align="middle">
          <Col span={4}>
            <Radio
              style={addOption === 1 ? radioStyleActive : radioStyle}
              value={1}
            >
              分享二维码给供应商
            </Radio>
          </Col>
          <Col span={12}>供应商通过微信扫一扫即可</Col>
          <Col span={4}>
            <Button type="primary" onClick={generateAndDownloadBarcode}>
              生成并下载二维码
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row style={addOption === 2 ? rowStyleActive : rowStyle} align="middle">
          <Col span={4}>
            <Radio
              style={addOption === 2 ? radioStyleActive : radioStyle}
              value={2}
            >
              发送链接给供应商
            </Radio>
          </Col>
          <Col span={12}>bbbb</Col>
        </Row>
        <Divider />
      </Radio.Group>
      {/* Following is just testing calling callback from child inner button */}
      <hr />
      <Button onClick={() => test()} type="default">
        Todo: associate this button with one of above operations so that its callback will
        ask parent page to refetch data.
      </Button>
    </>
  );
};

export default AddSupplier;
