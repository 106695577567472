import React, { useState } from "react";
import ReactDOM from "react-dom";
/* GraphQL */
import { ApolloProvider } from "@apollo/client";
import createClient from "./graphql/apollo_client";
/* Token */
import useLocalstorage from "@rooks/use-localstorage";
/* Router */
import { Router } from "react-router";
// import { renderRoutes } from "react-router-config";
import renderRouteConfig from "./commons/renderRoutes";
import { createBrowserHistory } from "history";
import routes from "./routes";
/* Hot Reloading */
import { hot } from "react-hot-loader/root";
/* Styling */
import "./App.scss";
import "./styles/tailwind.css";

const TokenContext = React.createContext();
const history = createBrowserHistory();

const App = () => {
  const [client, setClient] = useState(null);
  const currentCompanyId = localStorage.getItem("currentCompanyId");
  const tokenValue = localStorage.getItem("token");
  const useCompanyId = useLocalstorage("currentCompanyId", currentCompanyId);
  const useToken = useLocalstorage("token", tokenValue);

  React.useEffect(() => {
    setClient(createClient(useToken.value, useCompanyId.value));
  }, [useToken.value, useCompanyId.value]);

  if (client) {
    return (
      <TokenContext.Provider
        value={{
          setToken: useToken.set,
          setCompanyId: useCompanyId.set,
          removeToken: useToken.remove,
          removeCompanyId: useCompanyId.remove,
        }}
      >
        <ApolloProvider client={client}>
          <Router history={history}>{renderRouteConfig(routes, "/")}</Router>
        </ApolloProvider>
      </TokenContext.Provider>
    );
  } else {
    return null;
  }
};

const HotApp = hot(App);
export { TokenContext };
 
ReactDOM.render(<HotApp />, document.getElementById("root"));

