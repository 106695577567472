import React from "react";
import { Alert, Button } from "antd";
import { Link } from "react-router-dom";

const OrderGroupTableHeaderControls = ({
  orderGroups,
  orderMode,
  orderStatus,
  setDrawerVisible,
}) => {
  return (
    <Alert
      type="info"
      message={
        <div style={{ textAlign: "right" }}>
          {/* {orderMode === "sales" && orderStatus === "confirmed" && <Button onClick={() => editOrder()}>修改订单</Button>} */}
          {orderMode === "sales" && orderStatus === "CONFIRMED" && (
            <Link to={`/orders/peihuodan`}>配货单</Link>
          )}
          {orderMode === "purchase" && orderStatus === "SHIPPED" && (
            <Link to={`/orders/yanhuodan`}>验货单</Link>
          )}

          <Button
            onClick={() => {
              setDrawerVisible(true);
            }}
            style={{ marginLeft: 15 }}
          >
            查看全部
          </Button>
        </div>
      }
    />
  );
};

export default OrderGroupTableHeaderControls;
