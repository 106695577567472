import gql from "graphql-tag";
import { COMPANY_FRAGMENT } from "./company";

export const ORDER_GROUP_FRAGMENT = gql`
  fragment OrderGroup on OrderGroupType {
    id
    fromCompanyId
    fromCompany {
      name
    }
    toCompanyId
    toCompany {
      name
    }
  }
`;

export const ORDER_FRAGMENT = gql`
  fragment Order on OrderSQLAlchemyType {
    id
    fromCompany {
      name
    }
    toCompanyId
    toCompany {
      name
    }
    parentId
    shipToCompanyId
    originalSupplierId
    originalOrderCompanyId
    shipStatus
    orderStatus
    createdAt
    updatedAt
    hasSuborders
  }
`;

export const ORDER_ITEM_FRAGMENT = gql`
  fragment OrderItem on OrderItemType {
    id
    productName
    originalQty
    originalUnitPrice
    unit
  }
`;

export const SALES_ORDER_GROUPS = gql`
  query salesOrderGroups($orderStatus: OrderStatus) {
    salesOrderGroupsPage(orderStatus: $orderStatus) {
      total
      items {
        ...OrderGroup
        filteredOrders(orderStatus: $orderStatus) {
          ...Order
          filteredSuborders {
            ...Order
            orderItems {
              ...OrderItem
            }
          }
          fromCompany {
            ...CompanyF
          }
          toCompany {
            ...CompanyF
          }
          originalSupplier {
            ...CompanyF
          }
          orderItems {
            ...OrderItem
          }
        }
      }
    }
  }
  ${ORDER_GROUP_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${ORDER_ITEM_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

export const PURCHASE_ORDER_GROUPS = gql`
  query purchaseOrderGroupsPage($orderStatus: OrderStatus) {
    purchaseOrderGroupsPage(orderStatus: $orderStatus) {
      items {
        ...OrderGroup
        filteredOrders {
          ...Order
          orderItems {
            ...OrderItem
          }
          filteredSuborders {
            ...Order
            orderItems {
              ...OrderItem
            }
          }
          fromCompany {
            ...CompanyF
          }
          toCompany {
            ...CompanyF
          }
        }
      }
      total
    }
  }
  ${ORDER_GROUP_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${ORDER_ITEM_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

// rename to TAIZHANG_DATES
export const TAIZHANG_PAGE = gql`
  query TAIZHANG_PAGE($startDate: String, $endDate: String) {
    taizhangPage(startDate: $startDate, endDate: $endDate) {
      items {
        date
      }
      total
    }
  }
`;

export const TAIZHANG_DETAIL_PAGE = gql`
  query TAIZHANG_DETAIL_PAGE($startDate: String, $endDate: String) {
    purchasedOrderItems(startDate: $startDate, endDate: $endDate) {
      ...OrderItem
      order {
        createdAt
      }
    }
  }
  ${ORDER_ITEM_FRAGMENT}
`;

/* Mutations */
export const CONFIRM_ORDER = gql`
  mutation ConfirmOrder($orderId: ID!) {
    confirmOrder(orderId: $orderId) {
      ok
      message
    }
  }
`;

export const FORWARD_ORDER = gql`
  mutation ForwardOrder($orderId: ID!, $shipStatus: ShipStatus) {
    forwardOrder(orderId: $orderId, shipStatus: $shipStatus) {
      ok
      message
    }
  }
`;

export const SHIP_ORDER = gql`
  mutation ShipOrder($orderId: ID!) {
    shipOrder(orderId: $orderId) {
      ok
      message
    }
  }
`;

export const DELIVER_ORDER = gql`
  mutation DeliverOrder($orderId: ID!) {
    deliverOrder(orderId: $orderId) {
      ok
      message
    }
  }
`;
