/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { Spinner, GetDataError } from "../../components/common";

import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";
import { IS_LOGGED_IN } from "../../graphql/login";
import { Layout } from "antd";
import logo from "../../images/logo.png";
import "./style.scss";

export const Login = () => {
  const { data, error, loading } = useQuery(IS_LOGGED_IN);

  if (loading) return <Spinner />;
  if (error) {
    return <GetDataError />;
  }
  // 如果已经登录则 跳转到后台Home

  if (data.isLoggedIn) {
    return <Redirect to="/" />;
  } else {
  return (
    <Layout style={{ paddingTop: 150 }}>
      <div className="register">
        <div className="register__header">
          <img className="register__logo mx-auto" src={logo} />
        </div>

        <LoginForm />
      </div>
    </Layout>
  );
  }
};
