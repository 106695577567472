import React, { useState } from "react";
import { OperationType, ItemToSave } from "../../commons/models";
import { Input, Button, Space } from "antd";
import {
  EditOutlined,
  CheckCircleOutlined,
  RollbackOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const EditForm: any = ({
  item,
  currentId,
  onCurrentIdChanged,
  onSave,
}: {
  item: any;
  currentId: number;
  onCurrentIdChanged: (s: number) => void;
  onSave: (item: ItemToSave) => void;
}) => {
  //Note: currentId in above passed in params is parent selected item id which will determine if still staying in adding mode
  //onCurrentIdChanged is also used to notify parent that we have clicked Add button

  const [operationType, setOperationType] = useState(OperationType.Cancel);
  const [editName, setEditName] = useState(item.name);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await onSave({
      item: { ...item, name: editName },
      operationType: operationType,
    });
    setOperationType_notifyCurrentIdChanged(0, OperationType.Cancel); //restore to cancelled state
    setLoading(false);
  };

  function onEditNameChange(e) {
    setEditName(e.target.value);
  }

  function setOperationType_notifyCurrentIdChanged(id, type) {
    setOperationType(type);

    //notify parent currentId
    onCurrentIdChanged(type === OperationType.Cancel ? 0 : id);

    if (type !== OperationType.Edit) {
      //restore the original item name when cancel or delete
      setEditName(item.name);
    }
  }

  const editForm: any = () => {
    let content: any = null;
    if (item.id !== currentId || operationType === OperationType.Cancel) {
      content = (
        <Space className="my-2">
          <Input disabled value={item.name} />
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() =>
              setOperationType_notifyCurrentIdChanged(
                item.id,
                OperationType.Edit
              )
            }
          >
            编辑
          </Button>
          <Button
            type="default"
            icon={<CloseOutlined />}
            onClick={() =>
              setOperationType_notifyCurrentIdChanged(
                item.id,
                OperationType.Delete
              )
            }
          >
            删除
          </Button>
        </Space>
      );
    } else if (operationType === OperationType.Delete) {
      content = (
        <form key={item.id} onSubmit={handleSubmit}>
          <Space className="my-2">
            <Input bordered={false} value="确认要删除吗?" />
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              icon={<CheckCircleOutlined />}
            >
              确认
            </Button>
            <Button
              type="default"
              icon={<RollbackOutlined />}
              onClick={() =>
                setOperationType_notifyCurrentIdChanged(
                  item.id,
                  OperationType.Cancel
                )
              }
            >
              取消
            </Button>
          </Space>
        </form>
      );
    } else if (operationType === OperationType.Edit) {
      content = (
        <form key={item.id} onSubmit={handleSubmit}>
          <Space className="my-2">
            <Input required onChange={onEditNameChange} value={editName} />
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
              loading={loading}
            >
              保存
            </Button>
            <Button
              type="default"
              icon={<RollbackOutlined />}
              onClick={() =>
                setOperationType_notifyCurrentIdChanged(
                  item.id,
                  OperationType.Cancel
                )
              }
            >
              取消
            </Button>
          </Space>
        </form>
      );
    }
    return content;
  };

  return editForm();
};

export default EditForm;
