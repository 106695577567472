import { PiaozhengPage } from "./index";
import AppLayout from "../../../components/_layout";


const piaozhengRoutes = {
  path: "/piaozheng",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: PiaozhengPage,
    },
  ],
};

export default piaozhengRoutes;