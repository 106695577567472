import React, { FC, useState } from "react";
import { Redirect } from "react-router";
import { Layout } from "antd";
import AppHeader from "./Header";
import AppSider from "./Sider";
import { Typography } from "antd";
import "./index.scss";
import { Spinner, GetDataError } from "../common";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../../graphql/login";

const { Text, Link } = Typography;

const { Content, Footer } = Layout;

const AppLayout: FC = ({ children }) => {
  const { data, error, loading } = useQuery(IS_LOGGED_IN);

  if (loading) return <Spinner />;
  if (error) {
    return <GetDataError />;
  }

  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  if (data.isLoggedIn) {
    return (
      <Layout>
        <AppSider collapsed={collapsed}></AppSider>
        <Layout>
          <AppHeader isSiderCollapsed={collapsed} toggleSider={toggle} />
          <Content>{children}</Content>
          <Footer className="text-center">
            <Text type="secondary" style={{ fontSize: "0.8em" }}>
              Copyright ©{new Date().getFullYear()} 斯马千技术部出品
            </Text>
          </Footer>
        </Layout>
      </Layout>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

export default AppLayout;
