import { Login } from "./index";
import Logout from "../../components/Logout";

// const loginRoutes = [
//   {
//     name: "login",
//     path: "/login",
//     exact: true,
//     component: Login,
//   },
//   {
//     name: "logout",
//     path: "/logout",
//     exact: true,
//     component: Logout,
//   },
// ];

const loginRoutes = {
  path: "login",
  component: Login,
};

export default loginRoutes;
