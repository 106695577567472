import React from "react";
import { Button, Divider, Space } from "antd";

const CategoriesBar = ({
  supplier,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedSubcategoryId,
  setSelectedSubcategoryId,
}) => {
  if (!supplier) return <p>没有供应商</p>;

  return (
    <>
      <Space>
        <span>品类：</span>
        <Button
          //   value={null}
          onClick={() => setSelectedCategoryId(0)}
          ghost={selectedCategoryId ? false : true} // 如果没有选中subcategory, 用ghost+type="primary"来highlight这个button
          type={selectedCategoryId ? "default" : "primary"}
        >
          全部
        </Button>
        {supplier.categories.map((cat) => (
          <Button
            key={cat.id}
            onClick={() => {
              setSelectedCategoryId(cat.id);
            }}
          >
            {cat.name}
          </Button>
        ))}
      </Space>
      <Divider dashed className="my-2" />
      <Space>
        <span>子类：</span>
        <Button
          // value={null}
          onClick={() => setSelectedSubcategoryId(0)}
          ghost={selectedSubcategoryId ? false : true} // 如果没有选中subcategory, 用ghost+type="primary"来highlight这个button
          type={selectedSubcategoryId ? "default" : "primary"}
        >
          全部
        </Button>
        {supplier.categories
          .filter((cat) =>
            selectedCategoryId ? cat.id == selectedCategoryId : true
          )
          .map((cat) => {
            return cat.subcategories.map((sub) => {
              return (
                <Button
                  key={sub.id}
                  onClick={() => {
                    setSelectedSubcategoryId(sub.id);
                  }}
                >
                  {sub.name}
                </Button>
              );
            });
          })}
      </Space>
    </>
  );
};

export default CategoriesBar;
