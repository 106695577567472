import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
  Alert,
  Badge,
  Button,
  Input,
  Select,
  Switch,
  Table,
  message,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { TAIZHANG_DETAIL_PAGE } from "../../../graphql/order";

const TaizhangTable = ({ selectedDate }) => {
  const { data, loading, error, refetch } = useQuery(TAIZHANG_DETAIL_PAGE, {
    variables: {
      startDate: selectedDate,
      endDate: selectedDate,
    },
  });

  if (loading) return <>Loading...</>;
  if (error) return <>加载票据出错. Error loading LICENSE_PAGE</>;
  console.log("dta", data);

  const columns = [
    {
      title: "进货日期",
      //   dataIndex: "createdAt",
      render: (oi) => oi.order.createdAt.substring(0, 10),
    },
    {
      title: "产品名称",
      dataIndex: "productName",
    },
    {
      title: "规格",
      // dataIndex: "product.name",
      render: (oi) => "￥" + oi.originalUnitPrice + "/" + oi.unit,
    },
    {
      title: "数量",
      dataIndex: "originalQty",
    },
    {
      title: "生产者",
      // dataIndex: "originalQty",
    },
    {
      title: "地址及联系方式",
      // dataIndex: "originalQty",
    },
    {
      title: "供货者",
      dataIndex: "product.company.name",
    },
    {
      title: "地址及联系方式",
      // dataIndex: "originalQty",
    },
    {
      title: "许可证（如有）",
      // dataIndex: "product.company.name",
    },
  ];

  return (
    <Table
      dataSource={data.purchasedOrderItems}
      columns={columns}
      rowKey={(order_item) => order_item.id}
      locale={{
        emptyText: "没有台账",
      }}
      pagination={false}
    />
  );
};

export default TaizhangTable;
