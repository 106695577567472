import { PRODUCT_FRAGMENT } from "./product";
import gql from "graphql-tag";

export const ALL_HEADER_DATA = gql`
  query AllHeaderData {
    currentUserName @client

    getCarts {
      id
      companyId
      supplierId
      supplier {
        name
      }
      cartItems {
        id
        cart {
          supplierId
        }
        product {
          ...Product
        }
        qty
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;
