import React from "react";
import { Breadcrumb } from "antd";
import { Link, withRouter } from "react-router-dom";
import { MenuLinks } from "./_layout/MenuLinks";

const BreadcrumbWrapper = ({ location }) => {
  function getCurrentItems() {
    let items = Array<any>();
    const mainLevel = MenuLinks.find((item) => item.url === location.pathname);
    if (mainLevel) {
      items.push(mainLevel.name);
    } else {
      // then now current location is not first-level item. Let's try to find it in subItems
      const currentSubItem = MenuLinks.find(
        (item) =>
          item.subItems &&
          item.subItems.find((item) => item.url === location.pathname)
      );

      if (currentSubItem) {
        const currentItem = currentSubItem.subItems?.find(
          (item) => item.url === location.pathname
        );
        items.push(currentSubItem.name);
        items.push(currentItem?.name);
      }
    }
    return items;
  }

  let currentItems = getCurrentItems() || [];
  return (
    <Breadcrumb>
      {currentItems.map((item, idx) => (
        <Breadcrumb.Item key={idx}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default withRouter(BreadcrumbWrapper);
