import {
  CONFIRM_ORDER,
  FORWARD_ORDER,
  SHIP_ORDER,
  DELIVER_ORDER,
} from "../../../graphql/order";


export class OrderStatusModel {
  key!: string;
  tabText!: string;
  textForNextStatus!: string;
  nextStatus: OrderStatusModel | null;
  mutation: any;
  constructor(t?: OrderStatusModel) {
    if (!t) {
      this.key = "";
      this.tabText = "";
      this.textForNextStatus = "";
      this.nextStatus = null;
      this.mutation = null;
    } else {
      this.key = t.key;
      this.tabText = t.tabText;
      this.textForNextStatus = t.textForNextStatus;
      this.nextStatus = t.nextStatus;
      this.mutation = t.mutation;
    }
  }
}

export class OrderStatusTypes {
  static PENDING_FORWARD: OrderStatusModel = {
    key: "PENDING_FORWARD",
    tabText: "待转单",
    textForNextStatus: "转发订单",
    get nextStatus() {
      return OrderStatusTypes.PENDING;
    },
    mutation: FORWARD_ORDER,
  };
  static PENDING: OrderStatusModel = {
    key: "PENDING",
    tabText: "待接单",
    textForNextStatus: "接单",
    get nextStatus() {
      return OrderStatusTypes.CONFIRMED;
    },
    mutation: CONFIRM_ORDER,
  };
  static CONFIRMED: OrderStatusModel = {
    key: "CONFIRMED",
    tabText: "待发货",
    textForNextStatus: "发货",
    get nextStatus() {
      return OrderStatusTypes.SHIPPED;
    },
    mutation: SHIP_ORDER,
  };
  static SHIPPED: OrderStatusModel = {
    key: "SHIPPED",
    tabText: "运输中",
    textForNextStatus: "确认收货",
    get nextStatus() {
      return OrderStatusTypes.DELIVERED;
    },
    mutation: DELIVER_ORDER,
  };
  static DELIVERED: OrderStatusModel = {
    key: "DELIVERED",
    tabText: "已完成",
    textForNextStatus: "",
    get nextStatus() {
      return null;
    },
    mutation: null,
  };
  static RETURNED: OrderStatusModel = {
    key: "RETURNED",
    tabText: "退货",
    textForNextStatus: "",
    get nextStatus() {
      return null;
    },
    mutation: null,
  };
}
