import React from "react";
import { Switch, Route } from "react-router-dom";
import _ from "lodash";
import { pbkdf2 } from "crypto";

export function renderRoutes(routes, extraProps = {}, switchProps = {}) {
  return routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props) =>
            route.render ? (
              route.render({ ...props, ...extraProps, route: route })
            ) : (
              <route.component {...props} {...extraProps} route={route} />
            )
          }
        />
      ))}
    </Switch>
  ) : null;
}

const children = []; // children component list
// todo  debug routes
export function renderRouteConfigV3(routes, contextPath) {
  // Resolve route config object in React Router v3.
  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, "/");
    // console.log("newContextPath:....", newContextPath);

    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath);
      children.push(
        <Route
          key={newContextPath}
          exact
          render={(props) => (
            <item.component {...props}>{childRoutes}</item.component>
          )}
          path={newContextPath}
        />
      );
    } else if (item.component) {
      if (item.not_found) {
        children.push(
          <Route
            key={newContextPath}
            component={item.component}
            path={newContextPath}
            exact
          />
        );
      } else {
        children.push(
          <Route
            key={newContextPath}
            exact
            component={item.component}
            path={newContextPath}
          />
        );
      }
      // if (newContextPath === "/orders/orderdetail") {
      //   console.log(children);

      // }
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };

  routes.forEach((item) => renderRoute(item, contextPath));
  // console.log(children);

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
  // return children;
}

export default function renderRouteConfig(routes, contextPath) {
  // Resolve route config object in React Router v3.
  let children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    // console.log("item", item);

    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, "/");
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfig(item.childRoutes, newContextPath);
      children.push(
        <Route
          key={newContextPath}
          render={(props) => (
            <item.component {...props}>{childRoutes}</item.component>
          )}
          path={newContextPath}
        />
      );
    } else if (item.component) {
      children.push(
        <Route
          key={newContextPath}
          component={item.component}
          path={newContextPath}
          exact
        />
      );
    } else if (item.childRoutes) {
      _.forEach(item.childRoutes, (r) => renderRoute(r, newContextPath));
    }
  };

  _.forEach(routes, (item) => renderRoute(item, contextPath));
  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
}
