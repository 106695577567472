import React, { useCallback, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import axios from "axios";
import APPCONFIG from "../../../config";
import { Breadcrumb, Button, Table, Tabs, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { GET_SUPPLIER_COMPANIES_QUERY } from "../../../graphql/company";
import { UPLOAD_PRODUCT_EXCEL_MUTATION } from "../../../graphql/product";
import { Spinner, GetDataError } from "../../../components/common";

const ProductBulkPage = () => {
  const { data, loading, error } = useQuery(GET_SUPPLIER_COMPANIES_QUERY);

  const [fromWhereTab, setFromWhereTab] = useState(0); // 0:从供应商; 1:从商品模板

  /* Upload */
  const [uploadProductExcelMutation] = useMutation(
    UPLOAD_PRODUCT_EXCEL_MUTATION
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      // setUpLoading({ size: "large", tip: "上传中..." });
      uploadProductExcelMutation({
        variables: {
          fileIn: acceptedFiles[0],
        },
        update(
          cache,
          {
            data: {
              uploadProductExcel: { ok },
            },
          }
        ) {
          // console.log(ok);
          // setUpdateProductTable(updateProductTable + 1);
          // setUpLoading(false);
          message.success("上传完成!");
        },
      });
    },
    [uploadProductExcelMutation]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  /* Download */
  const handleExport = () => {
    // const companyId = localStorage.getItem("currentCompanyId");
    const companyId = 3;
    const baseUrl = APPCONFIG.API_URL;

    /* Download files with AJAX (axios): https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743 */
    axios({
      url: `${baseUrl}/company/${companyId}/product_template`,
      method: "POST",
      // data: {
      //     // product_ids: selectedProductIds
      //     // category_id: selectedCategoryId, // optional
      // },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const columns = [
    {
      title: "供应商列表",
      // dataIndex: "name",
      sorter: true,
      render: (supplier) => (
        <Link to={`/products/import_from_supplier?supplier_id=${supplier.id}`}>
          {supplier.name}
        </Link>
      ),
    },
  ];

  if (error) {
    return <GetDataError />;
  }
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="c0 pb-0">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/products">商品库</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>批量添加商品</Breadcrumb.Item>
        </Breadcrumb>
        <h2>导入商品库</h2>

        <Button
          type={!fromWhereTab ? "link" : "text"}
          className="tab-style"
          onClick={() => setFromWhereTab(0)}
        >
          从供应商
          <Tooltip title="从供应商导入的商品，买家所下的订单由系统生成采购单发送给相应供应商。">
            <InfoCircleOutlined style={{ color: "#000", marginLeft: 5 }} />
          </Tooltip>
        </Button>
        <Button
          type={fromWhereTab ? "link" : "text"}
          className="tab-style"
          onClick={() => setFromWhereTab(1)}
        >
          从商品模板
        </Button>
      </div>
      <div className="c1">
        {!fromWhereTab && (
          <Table
            dataSource={data.suppliers}
            columns={columns}
            rowKey={(supplier) => `supplier_${supplier.id}`}
            loading={loading}
          />
        )}
        {fromWhereTab === 1 && (
          <>
            <div style={{ marginLeft: 30, marginTop: 45, marginBottom: 50 }}>
              <span style={{ marginRight: 20 }}>下载:</span>

              <Button type="primary" onClick={() => handleExport()}>
                下载商品模板
              </Button>
            </div>

            <div style={{ marginLeft: 30, marginBottom: 50 }}>
              <span style={{ marginRight: 20 }}>上传:</span>

              <span {...getRootProps()}>
                <input {...getInputProps()} />
                <Button type="primary">上传商品模板</Button>
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProductBulkPage;
