import React, { useState, useEffect, FC } from "react";
import { Alert, Button, Drawer, Table, Space } from "antd";
import { Spinner, GetDataError } from "../../../components/common";
import {
  SALES_ORDER_GROUPS,
  PURCHASE_ORDER_GROUPS,
} from "../../../graphql/order";

import { useQuery } from "@apollo/client";
import OrderGroupDetailTable from "./OrderGroupDetailTable";
import OrderGroupTableHeaderControls from "./OrderGroupTableHeaderControls";

const OrderGroupTable = ({
  orderMode,
  orderStatus,
  refreshCount,
}) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [update, setUpdate] = useState(1);
  const [selectedOrderGroupId, setSelectedOrderGroupId] = useState<
    null | number
  >(null); // 当前展示的 orderGroup(s)

  const { data, error, loading, refetch } = useQuery(
    orderMode === "sales" ? SALES_ORDER_GROUPS : PURCHASE_ORDER_GROUPS,
    {
      variables: {
        orderStatus: orderStatus,
        ...pagination,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <GetDataError />;
  }

  const orderGroups =
    orderMode === "sales"
      ? data.salesOrderGroupsPage
      : data.purchaseOrderGroupsPage;

  const columns = [
    {
      title: "订单号",
      dataIndex: "id",
    },
    ...(orderMode === "purchase"
      ? [
          {
            title: "供应商",
            render: (orderGroup) => orderGroup.toCompany.name,
          },
        ]
      : []),
    ...(orderMode === "sales"
      ? [
          {
            title: "买家",
            render: (orderGroup) => orderGroup.fromCompany.name,
          },
        ]
      : []),
    {
      title: "查看详情",
      render: (orderGroup) => (
        <Button
          onClick={() => {
            setSelectedOrderGroupId(orderGroup.id);
            setDrawerVisible(true);
          }}
        >
          查看
        </Button>
      ),
    },
  ];
  console.log("orderGroups", orderGroups);
  return (
    <>
      <OrderGroupTableHeaderControls
        orderGroups={orderGroups}
        orderMode={orderMode}
        orderStatus={orderStatus}
        setDrawerVisible={setDrawerVisible}
      />
      <Table
        dataSource={orderGroups.items}
        columns={columns}
        rowKey={(orderGroup) => orderGroup.id}
      />
      <Drawer
        title="订单详情"
        placement="right"
        closable={true}
        mask={true}
        onClose={() => {
          setDrawerVisible(false);
          setSelectedOrderGroupId(null);
        }}
        width={window.innerWidth > 900 ? 800 : window.innerWidth - 50}
        visible={drawerVisible}
      >
        <OrderGroupDetailTable
          orderGroups={orderGroups}
          orderMode={orderMode}
          orderStatus={orderStatus}
          selectedOrderGroupId={selectedOrderGroupId}
          refetch={refetch}
        />
      </Drawer>
    </>
  );
};

export default OrderGroupTable;
