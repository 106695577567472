import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import { Button, Table, Tag, Breadcrumb } from "antd";
import { ButtonWithDrawer } from "../../../components/ButtonWithDrawer";
import { Spinner, GetDataError } from "../../../components/common";
import AddSupplier from "./components/AddSupplier";
import SetSupplierPermission from "./components/SetSupplierPermission";
import {
  MY_SUPPLIERS_PAGE,
  PASS_SUPPLIER_REQUEST,
  REMOVE_UNPASSED_SUPPLIER,
} from "../../../graphql/company";
import { MyCompany } from "../../../commons/models";

export const SuppliersPage: React.FC = () => {
  const [selectedSupplier, setSelectedSupplier] = useState<MyCompany | null>(
    null
  );
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { data, loading, error, refetch } = useQuery(MY_SUPPLIERS_PAGE, {
    variables: { ...pagination },
  });

  if (error) {
    return <GetDataError />;
  }
  if (loading) {
    return <Spinner />;
  }

  const suppliers = data.mySuppliersPage.items || [];
  console.log("suppliers", suppliers, selectedSupplier);

  const columns = [
    {
      title: "供应商名",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "状态",
      render: (supplier) => {
        if (supplier.relationStatus === "CompanyRelationStatus.accepted") {
          return <span>已关联</span>;
        } else {
          return <span>未关联</span>;
        }
      },
    },
    {
      title: "权限",
      render: (supplier) => {
        if (supplier.relationStatus === "CompanyRelationStatus.accepted") {
          return (
            <>
              {supplier.allowAutoDeliverOrder ? (
                <Tag color="processing">自动收货</Tag>
              ) : (
                <></>
              )}
              {supplier.allowModifyOrderPrice ? (
                <Tag color="error">订单改价</Tag>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return <Tag color="default">未设置</Tag>;
        }
      },
    },
    {
      title: () => <span className="ml-16px">操作</span>,
      render: (supplier) => {
        if (supplier.relationStatus === "CompanyRelationStatus.accepted") {
          return (
            <ButtonWithDrawer
              button={<Button type="link">设置</Button>}
              width={800}
              title={supplier.name}
              onConfirm={refetch}
              maskClosable={false}
            >
              <SetSupplierPermission initData={supplier} />
            </ButtonWithDrawer>
          );
        } else if (
          supplier.relationStatus === "CompanyRelationStatus.pending"
        ) {
          return <span className="ml-16px">设置</span>;
        }
      },
    },
  ];

  return (
    <>
      <div className="c0">
        <Breadcrumb>
          <Breadcrumb.Item>我的供应商</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>供应商管理</h2>
      </div>
      <div className="c1">
        <div className="text-right pb-3">
          <ButtonWithDrawer
            button={<Button type="primary">添加供应商</Button>}
            width={800}
            title="添加供应商"
            onConfirm={refetch}
            hasConfirm={false}
            maskClosable={false}
          >
            <AddSupplier />
          </ButtonWithDrawer>
        </div>

        <Table
          columns={columns}
          dataSource={suppliers.filter(
            (supplier) =>
              supplier.relationStatus !== "CompanyRelationStatus.denied"
          )}
          pagination={false}
          bordered={false}
          rowKey={(supplier) => supplier.id}
        />
      </div>
    </>
  );
};
