import React, { useState } from "react";
import { OperationType, ItemToSave } from "../../commons/models";
import { Input, Button, Space } from "antd";
import {
  PlusOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const AddNewForm: any = ({
  currentId,
  onCurrentIdChanged,
  onSave,
}: {
  currentId: number;
  onCurrentIdChanged: (s: number) => void;
  onSave: (item: ItemToSave) => void;
}) => {
  //Note: currentId in above passed in params is parent selected item id which will determine if still staying in adding mode
  //onCurrentIdChanged is also used to notify parent that we have clicked Add button

  const [operationType, setOperationType] = useState(OperationType.Cancel);
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddSumbit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await onSave({
      item: { id: 0, name: newName },
      operationType: OperationType.Add,
    });
    setOperationType_notifyCurrentIdChanged(OperationType.Cancel); //restore to cancelled state
    setLoading(false);
  };

  function onAddNameChange(e) {
    setNewName(e.target.value);
  }

  function setOperationType_notifyCurrentIdChanged(type: OperationType) {
    setOperationType(type);

    if (type === OperationType.Cancel) {
      setNewName(""); //restore the original item name when cancel
    } else {
      onCurrentIdChanged(0); //notify parent currentId
    }
  }

  return (
    <>
      {(currentId !== 0 || operationType === OperationType.Cancel) && (
        <Space className="my-2">
          <Button
            icon={<PlusOutlined />}
            type="dashed"
            htmlType="button"
            onClick={() => {
              setOperationType_notifyCurrentIdChanged(OperationType.Add);
            }}
          >
            添加
          </Button>
        </Space>
      )}
      {currentId === 0 && operationType === OperationType.Add && (
        <form onSubmit={handleAddSumbit}>
          <Space className="my-2">
            <Input
              type="text"
              name="newName"
              required
              placeholder="名称"
              onChange={onAddNameChange}
              value={newName}
            />
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              loading={loading}
            >
              保存
            </Button>
            <Button
              type="default"
              icon={<RollbackOutlined />}
              onClick={() =>
                setOperationType_notifyCurrentIdChanged(OperationType.Cancel)
              }
            >
              取消
            </Button>
          </Space>
        </form>
      )}
    </>
  );
};

export default AddNewForm;
