import React, { useCallback, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Input, Tabs, Breadcrumb, Select } from 'antd';
import _ from "lodash";

import { Spinner, GetDataError } from '../../../components/common';
import { SearchOutlined } from '@ant-design/icons';
import { ALL_DKS } from '../../../graphql/piaozheng';
import ZhengzhaoTable from './components/zhengzhaoTable';
const { Option } = Select;

const { TabPane } = Tabs;

export const ZhengzhaoPage: React.FC = () => {
  const [dkId, setDkId] = useState<number>();
  const [supplierName, setSupplierName] = useState<string>();
  const [zzType, setZztype] = useState<any>();

  const delayedQuery = useCallback(_.debounce(q => setSupplierName(q), 1000), []);
  const { data, loading, error, refetch } = useQuery(ALL_DKS);
  const handleTabs = (e) => {
    if (e == 1) {
      setZztype(null)
    }

    if (e == 2) {
      setZztype("YYZZ")
    }
    if (e == 3) {
      setZztype("SPXKZ")
    }
    if (e == 4) {
      setZztype("OTHERS")
    }

  };

  if (error) return <GetDataError />;
  if (loading) return <Spinner />;
  // console.log(data);

  return (
    <div className="x">
      <div className="c0">
        <Breadcrumb>
          <Breadcrumb.Item>证照管理</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>查看证照</h2>
      </div>

      <div className="c1 pb-0">
        <div className="mb-4">
          <span className="mr-2 text-sm">按档口查询:</span>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="选择档口"
            optionFilterProp="children"
            defaultValue={0}
            onChange={(value: number) => {
              setDkId(value)
            }}

          >

            <Option value={0} >
              全部档口
            </Option>
            {data.allDks.map((dk) => (
              <Option value={dk.id} key={`dk-${dk.id}`}>
                {dk.name}
              </Option>
            ))}
          </Select>
          <span className="ml-16 mr-2 text-sm">按供应商查询:</span>{' '}
          <Input
            size="small"
            className="w-40 h-8"
            placeholder="输入供应商"
            onChange={(e) => {
              delayedQuery(e.target.value)
            }}
          />
          {/* <Button
            type="primary"
            className="ml-8"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            搜索
          </Button> */}
        </div>

        <Tabs defaultActiveKey="1" onChange={handleTabs}>
          <TabPane tab="全部" key="1"></TabPane>
          <TabPane tab="营业执照" key="2"></TabPane>
          <TabPane tab="食品经营许可证" key="3"></TabPane>
          <TabPane tab="其他" key="4"></TabPane>
        </Tabs>
        <ZhengzhaoTable
          zzType={zzType}
          dkId={dkId}
          supplierName={supplierName}
        />
      </div>
    </div>
  );
};
