import { OrderPage } from "./index";
import AppLayout from "../../components/_layout";

// const orderRoutes = [{
//   name: "orders",
//   path: "/orders",
//   exact: true,
//   component: OrderPage
// }];
const orderRoutes = {
  path: "orders",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: OrderPage,
    },
  ],
};

export default orderRoutes;
