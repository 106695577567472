// #import "./categoryFragment.graphql"
import gql from "graphql-tag";
import { PRODUCT_FRAGMENT } from "./product";

export const GET_CARTS = gql`
  query getCarts {
    getCarts {
      id
      companyId
      supplierId
      supplier {
        name
      }
      cartItems {
        id
        cart {
          supplierId
        }
        product {
          ...Product
        }
        qty
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const ADD_TO_CART = gql`
  mutation AddToCart($cartItem: CartItemInput!) {
    addToCart(cartItem: $cartItem) {
      ok
      # ts
    }
  }
`;

export const REMOVE_CART_ITEMS = gql`
  mutation RemoveCartItems($ids: [Int]!) {
    removeCartItems(ids: $ids) {
      ok
    }
  }
`;

export const SUBMIT_CARTS = gql`
  mutation SubmitCarts($carts: [CartInput]!) {
    submitCarts(carts: $carts) {
      ok
    }
  }
`;
