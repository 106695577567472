import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Dropdown,
  Row,
  Col,
  Tabs,
  Table,
  Menu,
} from "antd";
import TaizhangDateTable from "./TaizhangDateTable";
import TaizhangTable from "./TaizhangTable";
import moment from "moment";
import BreadcrumbWrapper from "../../../components/BreadcrumbWrapper";

const { TabPane } = Tabs;

const TaizhangPage = () => {
  const [startDate, setStartDate] = useState(moment()); // maybe use moment().format("YYYY-MM-DD")
  const [endDate, setEndDate] = useState(moment());
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  function onDateChange(dates, dateStrings) {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  }

  return (
    <>
      <div className="c0">
        <BreadcrumbWrapper />
        <h2>进货查验记录表格</h2>
      </div>
      <div className="c1">
        <Row
          style={{
            marginLeft: 20,
            marginRight: 20,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Col span={16}>
            <span>按时间段查询</span>
            <DatePicker.RangePicker
              value={[startDate, endDate]}
              placeholder={["开始日期", "结束日期"]}
              style={{ marginLeft: 20 }}
              onChange={onDateChange}
            />
          </Col>
        </Row>

        <TaizhangDateTable
          startDate={startDate}
          endDate={endDate}
          setDrawerVisible={setDrawerVisible}
          setSelectedDate={setSelectedDate}
        />

        <Drawer
          title={
            <h3 style={{ textAlign: "center", margin: 0, padding: 0 }}>
              {selectedDate}台账详情
            </h3>
          }
          placement="right"
          closable={true}
          mask={true}
          onClose={() => {
            setDrawerVisible(false);
          }}
          width={800}
          // bodyStyle={{ padding: 5 }}
          visible={drawerVisible}
        >
          <TaizhangTable selectedDate={selectedDate} />
        </Drawer>
      </div>
    </>
  );
};

export default TaizhangPage;
