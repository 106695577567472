import React from "react";
import { Layout, Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import logo1 from "../../images/logo1.png";
import logo2 from "../../images/logo2.png";
import "./Sider.scss";
import { MenuLinks } from "./MenuLinks";

const { Sider } = Layout;
const { SubMenu } = Menu;

const AppSider = (props) => {
  const { collapsed, location } = props;

  function getOpenKeys() {
    if (!MenuLinks.find((item) => item.url === location.pathname)) {
        // then now current location is not first-level item. Let's try to find it in subItems
      const currentSubItem = MenuLinks.find(
        (item) =>
          item.subItems &&
          item.subItems.find((item) => item.url === location.pathname)
      );

      if (currentSubItem) {
        return [currentSubItem.name];
      } else {
        return [];
      }
    }
    return [];
  }

  let defaultOpenKeys = getOpenKeys() || [];

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Link to="/" className="brand">
        <img src={logo1} className="logo1" />
        <img src={logo2} className="logo2" />
      </Link>
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={defaultOpenKeys}
                // openKeys={["商品追溯"]}

      >
        {MenuLinks.map((link) =>
          link.subItems ? (
            <SubMenu
              key={link.name}
              title={link.name}
              icon={link.icon ? link.icon : null}
            >
              {link.subItems.map((subItem) => (
                <Menu.Item key={subItem.url}>
                  <Link to={subItem.url}>{subItem.name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={link.url} icon={link.icon ? link.icon : null}>
              <Link to={link.url}>{link.name}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </Sider>
  );
};

export default withRouter(AppSider);
