import React, { useState } from "react";
import { Avatar, Dropdown, Layout, Drawer, Popover, Menu, Badge } from "antd";
import { Link } from "react-router-dom";
import Carts from "../cart/Carts";
import CartIcon from "../cart/CartIcon";
import IconFont from "../iconfont";
import "./Header.scss";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
} from "@ant-design/icons";
import Logout from "../Logout";
import { useQuery, useMutation } from "@apollo/client";
import { ALL_HEADER_DATA } from "../../graphql/global";
import { Spinner, GetDataError } from "../common";
import { REMOVE_CART_ITEMS } from "../../graphql/cart";

const { Header } = Layout;

const AppHeader = ({ isSiderCollapsed, toggleSider }) => {
  const [cartVisible, setCartVisible] = useState(false);

  const [isCartItemsUpdated, setIsCartItemsUpdated] = useState(false);
  const [cartItemsToDelete, setCartItemsToDelete] = useState([]);

  const [removeCartItems] = useMutation(REMOVE_CART_ITEMS);
  const { data, error, loading, refetch } = useQuery(ALL_HEADER_DATA);

  const notifications = (
    <div>
      <p>Content Content Content Content Content </p>
      <p>ContentContent </p>
    </div>
  );

  const notifyCount = 5;

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <GetDataError />;
  }

  function closeCartsDrawer() {
    setCartVisible(false);

    if (isCartItemsUpdated) {
      if (cartItemsToDelete.length) {
        removeCartItems({
          variables: {
            ids: cartItemsToDelete,
          },
          update: (cache, mutationResult) => {
            if (mutationResult.data.removeCartItems.ok) {
              console.log("REMOVE CART ITEM SUCCESS");
              refetch();
            }
          },
        });
      } else {
        refetch();
      }
      setIsCartItemsUpdated(false);
    }
  }

  function handleCartItemsUpdates(itemIdsToDelete) {
    setIsCartItemsUpdated(true);
    setCartItemsToDelete(itemIdsToDelete);
  }

  const avatarDropdown = () => (
    <Menu className="app-header-dropdown">
      <Menu.Item key="1" className="d-block">
        <strong>{data.currentUserName}</strong>
      </Menu.Item>
      <Menu.Divider className="d-block d-md-none" />
      {/* <Menu.Item key="2">
        <Link className="menu-item-link" to={`/setting/profile`}>
          个人信息
        </Link>
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item key="3">
        <Logout />
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="app-header">
      {React.createElement(
        isSiderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: toggleSider,
        }
      )}

      <div className="header-right">
        {/* <div>
          <CartIcon
            carts={data.getCarts}
            onClickHandler={() => setCartVisible(true)}
          >
            <IconFont
              className="list-notification-icon"
              type="iconcart"
              style={{ fontSize: "1.4em" }}
            />
          </CartIcon>
        </div>
        {notifyCount ? (
          <div>
            <Popover
              placement="bottomRight"
              title=""
              content={notifications}
              trigger="click"
            >
              <span className="cursor-pointer">
                <BellOutlined style={{ fontSize: "1.4em", fontWeight: 200 }} />
                <span className="badge-for-count">{notifyCount}</span>
              </span>
            </Popover>
          </div>
        ) : null} */}

        <div style={{ position: "relative", top: -4 }}>
          <Dropdown
            className="list-inline-item"
            key="user"
            overlay={avatarDropdown}
            // trigger={['click']}
            placement="bottomRight"
          >
            <a className="ant-dropdown-link no-link-style">
              <Avatar
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                size={20}
              >
                {data.currentUserName
                  ? data.currentUserName.substring(0, 1)
                  : null}
              </Avatar>
              {data.currentUserName}
            </a>
          </Dropdown>
        </div>
      </div>

      <Drawer
        title={
          <>
            <CartIcon carts={data.getCarts}>
              <IconFont
                className="list-notification-icon"
                type="iconcart"
                style={{ fontSize: "2em" }}
              />
            </CartIcon>
            <span className="mx-3">采购车详情</span>
          </>
        }
        placement="right"
        closable={true}
        mask={true}
        onClose={closeCartsDrawer}
        width={window.innerWidth > 1000 ? 900 : window.innerWidth - 100}
        visible={cartVisible}
      >
        <Carts carts={data.getCarts} callback={handleCartItemsUpdates} />
      </Drawer>
    </Header>
  );
};

export default AppHeader;
