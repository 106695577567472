import React, { useState, useEffect } from "react";
import { InputNumber, Table } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CARTS,
  ADD_TO_CART,
  REMOVE_CART_ITEMS,
} from "../../../graphql/cart";
import { MALL_PRODUCTS } from "../../../graphql/mall";
import { Spinner, GetDataError } from "../../../components/common";

const MallTableView = ({
  selectedSupplierId,
  selectedCategoryId,
  selectedSubcategoryId,
  priceGroupId,
  cartItems,
  pagination,
  setPagination,
}) => {
  const [addToCartMutation] = useMutation(ADD_TO_CART);
  const [removeCartItems] = useMutation(REMOVE_CART_ITEMS);

  const { data, loading, error, refetch } = useQuery(MALL_PRODUCTS, {
    variables: {
      ...pagination,
      supplierId: selectedSupplierId,
      categoryId: selectedCategoryId,
      subcategoryId: selectedSubcategoryId,
    },
  });

  // We have to use cartItemsMutable that will be updated through useEffect when cartItems are updated.
  // This is a good solution to get the quantities in this page updated when they are updated by the Carts.tsx in drawer.
  const [cartItemsMutable, setCartItemsMutable] = useState<Array<any> | null>(
    Array<any>()
  );

  useEffect(() => {
    if (data && data.mallProducts) {
      setPagination({ ...pagination, total: data.mallProducts.total });
    }
  }, [data]);

  useEffect(() => {
    setCartItemsMutable(cartItems);
  }, [cartItems]);

  if (loading) return <Spinner />;
  if (error) return <GetDataError />;

  const handlePagerChange = (pager) => {
    setPagination({ ...pager });
  };

  const handleQuantityChange = (product, qty) => {
    let newCartItems;
    if (
      !cartItemsMutable ||
      !cartItemsMutable.length ||
      cartItemsMutable.findIndex(
        (cartItem) => cartItem.product.id === product.id
      ) === -1
    ) {
      newCartItems = [
        ...(cartItemsMutable ? cartItemsMutable : []),
        {
          product: product,
          qty: qty,
          timeout: setTimeout(() => {
            addToCart(product, qty);
          }, 1000),
        },
      ];
    } else {
      newCartItems = cartItemsMutable.map((cartItem) => {
        if (cartItem.product.id === product.id && cartItem.timeout) {
          clearTimeout(cartItem.timeout);
        }
        return cartItem.product.id === product.id
          ? {
              ...cartItem,
              qty: qty,
              timeout: setTimeout(() => {
                addToCart(product, qty);
              }, 1000),
            }
          : cartItem;
      });
    }

    setCartItemsMutable(newCartItems);
  };

  const addToCart = (product, qty) => {
    if (qty > 0) {
      addToCartMutation({
        variables: {
          cartItem: {
            supplierCompanyId: product.companyId, // 卖家的company_id
            productId: product.id,
            qty: qty,
          },
        },
        refetchQueries: [{ query: GET_CARTS }],
      });
    } else if (qty === 0 && cartItemsMutable) {
      const cartItem = cartItemsMutable.find(
        (item) => item.product.id === product.id
      );
      if (cartItem) {
        removeCartItems({
          variables: {
            ids: [cartItem.id],
          },
          refetchQueries: [{ query: GET_CARTS }],
          //Note: below will not update CartIcon and Carts, but above refetchQueries will. Don't know why!
          //   update: (cache, mutationResult) => {
          //     if (mutationResult.data.removeCartItems.ok) {
          //       refetch();
          //     }
          //   },
        });
      }
    }
  };

  const columns = [
    {
      title: "商品名称",
      dataIndex: "name",
    },
    {
      title: "描述",
      render: (product) => <span>{product.desc}</span>,
    },
    {
      title: "单位",
      dataIndex: "unit",
    },
    {
      title: "销售价",
      render: (product) => (
        <span>{product.customPrice || product.unitPrice} 元</span>
      ),
    },
    // {
    //   title: "供应商",
    //   render: (product) => {
    //     console.log(product);
    //   },
    // },
    {
      title: "频次",
      dataIndex: "orderCount",
    },
    {
      title: "状态",
      render: (product) => {
        if (product.productStatus == "AVAILABLE") {
          return <span>已上架</span>;
        } else if (product.productStatus == "UNAVAILABLE") {
          return <span>已下架</span>;
        }
      },
    },
    {
      title: "数量",
      render: (product) => {
        const cartItem = cartItemsMutable
          ? cartItemsMutable.find(
              (cartItem) => cartItem.product.id === product.id
            )
          : null; // see if 当前的 productSpec 已经在购物车里

        return (
          <InputNumber
            /*If using defaultValue, it won't be updated if qty is updated from somewhere else. */
            value={cartItem ? cartItem.qty : 0}
            min={0}
            onChange={(val) => {
              if (typeof val === "number") {
                handleQuantityChange(product, val);
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      dataSource={data.mallProducts.items}
      columns={columns}
      rowKey={(product) => product.id}
      onChange={handlePagerChange}
      pagination={pagination}
      loading={loading}
    />
  );
};

export default MallTableView;
