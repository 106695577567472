import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, Checkbox, Table, Tag, message } from "antd";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  SUPPLIER_PRODUCT_PAGE,
  CATEGORY_PRODUCTS,
  IMPORT_PRODUCTS_MUTATION,
} from "../../../graphql/product";
import ProductImportModal from "./ProductImportModal";

const { CheckableTag } = Tag;

const SupplierProductImportPage = () => {
  const client = useApolloClient();
  const [ImportProductsMutation] = useMutation(IMPORT_PRODUCTS_MUTATION);

  const params = new URLSearchParams(window.location.search);
  const supplierId = params.get("supplier_id");
  const [loading, setLoading] = useState(false);
  const [
    importProductFromSupplierModalVisible,
    setImportProductFromSupplierModalVisible,
  ] = useState(false);

  /* current user's own company */
  const [categories, setCategories] = useState([]);
  const [exportToCategoryId, setExportToCategoryId] = useState(); // 把选中的产品导入到这个品类
  const [exportToSubcategoryId, setExportToSubcategoryId] = useState(); // 把选中的产品导入到这个子类

  /* supplier's categories related */
  const [supplierCategories, setSupplierCategories] = useState();
  const [checkedCategoryIds, setCheckedCategoryIds] = useState(Array());

  /* products related */
  const [selectedProductIdsToImport, setSelectedProductIdsToImport] = useState(
    Array()
  );
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 }); // 把pager封装到新的pagination里. pagination contains {current, pageSize, total}

  const handlePagerChange = (pager) => {
    setPagination({ ...pager });
  };

  /* 把刚刚被check/unchecked的category的products取来，添加到/从selectedProductIdsToImport中移除 */
  const fetchCategoryProducts = async (categoryId, checked) => {
    const result = await client.query({
      query: CATEGORY_PRODUCTS,
      variables: { categoryId: categoryId },
    });
    // return result.data.categoryProducts
    const fetchedProductIds = result.data.categoryProducts.map(
      (product) => product.id
    );

    if (checked) {
      const selectedIds = [...selectedProductIdsToImport, ...fetchedProductIds];
      setSelectedProductIdsToImport(selectedIds);
    } else {
      const selectedIds = selectedProductIdsToImport.filter(function (el) {
        return !fetchedProductIds.includes(el);
      });
      setSelectedProductIdsToImport(selectedIds);
    }
  };

  const fetchData = async (pager) => {
    setLoading(true);
    const result = await client.query({
      query: SUPPLIER_PRODUCT_PAGE,
      variables: {
        ...pager, // pageNum: $current, pageSize: $pageSize
        supplierId: supplierId,
      },
    });
    console.log("SUPPLIER_PRODUCT_PAGE", result);
    if (supplierCategories === undefined) {
      setSupplierCategories(result.data.supplierCategories);
    }

    setCategories(result.data.categories);

    setPagination({ ...pager, total: result.data.productPage.total });
    setProducts(result.data.productPage.items);

    setLoading(false);
  };

  useEffect(() => {
    // 如果判断 添加过产品 跳转到第一页
    fetchData({ current: pagination.current });
  }, [pagination.current]);

  /* 当supplier的category被选中时， */
  function handleSupplierCategoryChecked(checked, categoryId) {
    console.log("checked", checked, "categoryId", categoryId);
    setLoading(true);
    var selectedCategoryIds;
    if (checked) {
      selectedCategoryIds = [...checkedCategoryIds, categoryId];
    } else {
      selectedCategoryIds = checkedCategoryIds.filter(
        (el) => el !== categoryId
      );
    }
    setCheckedCategoryIds(selectedCategoryIds);

    /* 处理products, 是否打钩*/
    fetchCategoryProducts(categoryId, checked);
    setLoading(false);
  }

  const handleOK = (e) => {
    ImportProductsMutation({
      variables: {
        productIds: selectedProductIdsToImport,
        toCategoryId: exportToCategoryId,
        toSubcategoryId: exportToSubcategoryId,
      },
      update(
        cache,
        {
          data: {
            importProducts: { ok },
          },
        }
      ) {
        setImportProductFromSupplierModalVisible(false);
        setSelectedProductIdsToImport([]);
        // setUpdateProductTable(updateProductTable + 1)
        message.success(
          <span>
            拷贝商品成功！请在<a href="/products">商品管理页</a>查看
          </span>
        );
      },
    });
  };

  const columns = [
    {
      title: "商品名称",
      dataIndex: "name",
    },
    {
      title: "销售价",
      render: (product) => <span>{product.unitPrice} 元</span>,
    },
    {
      title: "单位",
      dataIndex: "unit",
    },
    {
      title: "状态",
      // render: product => (
      //     <Switch checkedChildren="上架" unCheckedChildren="下架" checked={product.status == "AVAILABLE"} onClick={(checked, e) => setInStock(checked, e, product)} />
      // )
    },
  ];

  const rowSelection = {
    onSelect: (product, selected, selectedRows, nativeEvent) => {
      // do not use selectedRows to keep track of selectedProductIds, because it loses track of selected rows after pager changes
      var selectedIds;
      if (selected) {
        selectedIds = [...selectedProductIdsToImport, product.id];
      } else {
        // deselected
        selectedIds = selectedProductIdsToImport.filter(
          (product_id) => product_id !== product.id
        );
      }
      setSelectedProductIdsToImport(selectedIds);
      console.log("selectedProductIdsToImport", selectedProductIdsToImport);
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      const delta = changeRows.map((row) => row.id);
      let selectedIds;
      if (selected) {
        selectedIds = [...selectedProductIdsToImport, ...delta];
      } else {
        // deselected
        selectedIds = selectedProductIdsToImport.filter(function (el) {
          return !delta.includes(el);
        });
      }
      setSelectedProductIdsToImport(selectedIds);
    },
    selectedRowKeys: selectedProductIdsToImport,
  };

  return (
    <div>
      <h4 className="pm__breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/products">商品库</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/products">导入商品库</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>从供应商导入</Breadcrumb.Item>
        </Breadcrumb>
      </h4>

      <h3>导入到我的商品库</h3>

      {/* 品类 toolbar */}
      <div style={{ margin: "20px" }}>
        <span style={{ marginRight: "20px" }}>品类</span>
        {supplierCategories &&
          supplierCategories.map((cat) => (
            <span key={cat.id}>
              <Checkbox
                checked={checkedCategoryIds.indexOf(cat.id) !== -1}
                // indeterminate={true}
                onChange={(e) =>
                  handleSupplierCategoryChecked(e.target.checked, cat.id)
                }
              />
              <CheckableTag
                checked={checkedCategoryIds.indexOf(cat.id) !== -1}
                onChange={(e) => handleSupplierCategoryChecked(e, cat.id)}
                style={{ marginLeft: 2 }}
              >
                {cat.name}
              </CheckableTag>
            </span>
          ))}
      </div>

      <div style={{ margin: "20px" }}>
        <span style={{ marginRight: "20px" }}>子类</span>
        TODO
      </div>

      {/* 操作 toolbar */}
      <div style={{ margin: "20px" }}>
        <span style={{ marginRight: "20px" }}>操作</span>
        <Button
          type="primary"
          onClick={() => {
            setImportProductFromSupplierModalVisible(true);
          }}
        >
          导入
        </Button>
      </div>

      {/* Supplier's products */}
      <Table
        dataSource={products}
        columns={columns}
        rowKey={(product) => product.id}
        rowSelection={rowSelection}
        onChange={handlePagerChange}
        pagination={pagination}
        loading={loading}
      />

      <ProductImportModal
        visible={importProductFromSupplierModalVisible}
        setVisible={setImportProductFromSupplierModalVisible}
        categories={categories}
        exportToCategoryId={exportToCategoryId}
        setExportToCategoryId={setExportToCategoryId}
        exportToSubcategoryId={exportToSubcategoryId}
        setExportToSubcategoryId={setExportToSubcategoryId}
        handleOk={handleOK}
      />
    </div>
  );
};

export default SupplierProductImportPage;
