import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Tabs, Breadcrumb } from "antd";
import OrderModeTab from "./components/OrderModeTab";
import SaleOrders from "./SalesOrders";
import PurchaseOrders from "./PurchaseOrders";
import { Link } from "react-router-dom";

export const OrderPage: React.FC = () => {
  const [orderMode, setOrderMode] = useState(0); // 0:purchase; 1:sales

  return (
    <>
      <div className="c0 pb-0">
        <Breadcrumb>
          <Breadcrumb.Item>订单管理</Breadcrumb.Item>
          <Breadcrumb.Item>&nbsp;</Breadcrumb.Item>
        </Breadcrumb>
        <h2>订单管理</h2>
        <Button
          type={!orderMode ? "link" : "text"}
          className="tab-style"
          onClick={() => setOrderMode(0)}
        >
          <OrderModeTab dot={true}>销售订单</OrderModeTab>
        </Button>
        <Button
          type={orderMode ? "link" : "text"}
          className="tab-style"
          onClick={() => setOrderMode(1)}
        >
          <OrderModeTab dot={true}>采购订单</OrderModeTab>
        </Button>
      </div>
      <div className="c1">
        {!orderMode && <SaleOrders />}
        {orderMode === 1 && <PurchaseOrders />}
      </div>
    </>
  );
};
