import React, { useEffect, useCallback, useState } from 'react';
import { Button, message } from 'antd';
import { useDropzone } from 'react-dropzone';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import UploadList from 'antd/lib/upload/UploadList';
import produce from 'immer';

/**
 * 单个文件上传 返回 url
 * props mutation and update accept:Mime type
 *  */
export function useUploadOne(props) {
  const { mutation, accept, update, text } = props;
  // console.log(accept);

  const [uploadMutate] = useMutation(mutation);
  const [file, setFile] = useState();

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);

      uploadMutate({
        variables: {
          fileIn: acceptedFiles[0],
        },
        update(cache, result) {
          update(cache, result);
        },
      });
    },
    [update, uploadMutate],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: accept,
  });
  return [
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Button icon="upload">{text ? text : '上传'}</Button>
    </div>,
    file,
    setFile,
  ];
}

/**
 * 单个图片 随form 一起上传
 * props mutation and update accept:Mime type
 *  */
export function useUploadOneLocalImage(props) {
  const [image, setImage] = useState<Array<any>>([]);
  const { accept } = props;
  const onDrop = useCallback((acceptedFiles) => {
    // if (image) {
    //   return;
    // }
    setImage(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (typeof image == 'string') {
        return;
      }
      if (image) {
        image.forEach((file) => URL.revokeObjectURL(file['preview']));
      } else {
        return;
      }
    },
    [image],
  );

  let items;
  if (typeof image == 'string') {
    items = [{ uid: `preview_image_0`, url: image, index: 0 }];
  } else {
    console.log(image);
    if (image) {
      items = image.map((img, index) => {
        return {
          uid: `preview_image_${index}`,
          url: img['preview'],
          index: index,
        };
      });
    }
  }

  // console.log(images);

  return [
    <div className="flex_box">
      <UploadList
        listType="picture-card"
        items={items}
        locale={{ previewFile: '' }}
        showRemoveIcon={false}
        showPreviewIcon={false}
      />
      <div
        {...getRootProps({
          className:
            'ant-upload ant-upload-select ant-upload-select-picture-card',
        })}
      >
        <input {...getInputProps()} />
        <span className="ant-upload">
          <div>
            {/* <Icon type="plus" style={{ fontSize: "1.7em" }} /> */}
            <PlusOutlined />
          </div>
          <span>上传</span>
        </span>
      </div>
    </div>,
    image,
    setImage,
  ];
}

/**
 * accept: mine type
 *
 * .xlsx     application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
 * 'image/jpeg, image/png'
 */
export function useUploadMultiple(props) {
  const {
    accept,
    existingImages,
    piaozhengId,
    deleteImageCallback,
  } = props;

  const [images, setImages] = useState(existingImages);

  const onDrop = useCallback((acceptedFiles) => {
    const newStuff = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setImages([...images, ...newStuff]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      images.forEach((file) => URL.revokeObjectURL(file['preview']));
    },
    [images],
  );

  let items;
  items = images.map((img, index) => {
    return {
      uid: `preview_image_${index}`,
      url: img['preview'],
      index: index,
      // size: 0,
      // name: `preview_img_${index}`,
      // type: 'select',
    };
  });

  const handleRemove = (file) => {
    if (file) {
      console.log(file);
      const imageName = file.url.split('smqsmq.com/')[1];
      if (imageName) {
        deleteImageCallback({
          variables: {
            piaozhengId: piaozhengId,
            imageName: imageName,
          },
        });
      }
      const nextState = produce(images, (draftState) => {
        draftState.splice(file.index, 1); //删除 指定的file
      });
      setImages(nextState);
    }
    // console.log(images);
  };

  return [
    <div>
      <UploadList
        listType="picture-card"
        items={items}
        locale={{ previewFile: '' }}
        onRemove={handleRemove}
      />
      <div
        {...getRootProps({
          className:
            'ant-upload ant-upload-select ant-upload-select-picture-card',
        })}
      >
        <input {...getInputProps()} />
        <span className="ant-upload">
          <div>
            {/* <Icon type="plus" style={{ fontSize: "1.7em" }} /> */}
          </div>
          <span>上传</span>
        </span>
      </div>
    </div>,
    images,
    setImages,
  ];
}
