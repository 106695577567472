import React from "react";
import { Table } from "antd";
import OrderDetailHeader from "./OrderDetailHeader";
import OrderButton from "./OrderButton";

/* 点击某个 OrderGroup 跳出来的 Drawer 里面的 table */
const OrderGroupDetailTable = ({
  orderGroups,
  orderMode,
  orderStatus,
  selectedOrderGroupId,
  refetch,
}) => {
  const filteredOrderGroups = orderGroups.items.filter((orderGroup) =>
    selectedOrderGroupId ? orderGroup.id == selectedOrderGroupId : true
  );

  const columns = [
    {
      title: "商品名称",
      width: "20%",
      render: (orderItem) => {
        return orderItem.productName;
      },
    },
    {
      title: "数量",
      width: "20%",
      render: (orderItem) => {
        return orderItem.originalQty;
      },
    },
    {
      title: "单位",
      width: "20%",
      render: (row) => row.unit,
    },
    {
      title: "单价",
      width: "20%",
      render: (row) => {
        return row.originalUnitPrice;
      },
    },
  ];

  const renderSuborderItems = (porder) => {
    console.log("---render sub orderitems of order", porder.id);
    return (
      <div key={`order-${porder.id}`}>
        <OrderDetailHeader order={porder} />
        {porder.filteredSuborders.map((order) => {
          return renderOrderItems(porder, order);
        })}
      </div>
    );
  };

  const renderOrderItems = (porder, order) => {
    console.log("render orderitems, order=", order.id);

    return (
      <div key={`order-${order.id}`}>
        <OrderDetailHeader order={order} />
        <Table
          dataSource={order.orderItems}
          columns={columns}
          rowKey={(row) => row.id}
          pagination={false}
        />
        <OrderButton
          mode={orderMode}
          orderStatus={orderStatus}
          porder={porder}
          order={order}
          refetch={refetch}
        />
      </div>
    );
  };

  return (
    <>
      {filteredOrderGroups.map((orderGroup) => {
        return (
          <div key={`order-group-${orderGroup.id}`} className="orders">
            {orderGroup.filteredOrders.map((order) => {
              console.log("order is", order);
              // 以下相当于小程序的 order-card/index.js
              return order.hasSuborders
                ? renderSuborderItems(order)
                : renderOrderItems(null, order);
            })}
          </div>
        );
      })}
    </>
  );
};

export default OrderGroupDetailTable;
