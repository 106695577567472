import React from "react";
import { Row, Col, Descriptions } from "antd";

const OrderDetailHeader = ({ order }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Descriptions title="买家信息">
            <Descriptions.Item label="名称">
              {order.fromCompany.name}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions title="卖家信息">
            <Descriptions.Item label="名称">
              {order.toCompany.name}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

export default OrderDetailHeader;
