import React, { useState, useEffect, Fragment } from "react";
import { useMutation } from "@apollo/client";
import {
  Row,
  Col,
  Button,
  Card,
  Checkbox,
  Drawer,
  Divider,
  Form,
  Input,
  List,
  Radio,
  Space,
} from "antd";
import { UPDATE_EMPLOYEE_MUTATION } from "../../../graphql/user";

const EditRoleDrawer = ({
  abilityCategories,
  companyRoles,
  employee,
  setCurrentEmployee,
  visible,
  setVisible,
  refetch,
}) => {
  console.log("employee", employee, "companyRoles", companyRoles);
  const [selectedRole, setSelectedRole] = useState(); // 用来显示 which checkbox to tick
  const [updateEmployeeMutation] = useMutation(UPDATE_EMPLOYEE_MUTATION);
  const [form] = Form.useForm();

  useEffect(() => {
    if (employee) {
      setSelectedRole(
        companyRoles.find((role) => role.id == employee.roles[0].id)
      );
    }
  }, [employee]);

  if (!abilityCategories || !companyRoles || !employee) return <></>;
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      updateEmployeeMutation({
        variables: {
          userId: employee.id,
          realName: values.realName,
          phone: values.phone,
          oldRoleId: employee.roles[0].id,
          newRoleId: values.role,
        },
        update(cache, { data }) {
          setCurrentEmployee(null);
          setVisible(false);
          refetch();
        },
        // refetchQueries: [{ query: PRODUCT_PAGE_QUERY, variables: { current: 1 } }]
      });
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setCurrentEmployee(null);
  };

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
  };

  return (
    <Drawer
      visible={visible}
      maskClosable={true}
      onClose={handleCancel}
      destroyOnClose={true}
      width={800}
      footer={
        <Space>
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={handleSubmit}>确定</Button>
        </Space>
      }
    >
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name="realName"
          label="姓名"
          initialValue={employee ? employee.realName : null}
          rules={[{ required: true, message: "请输入姓名" }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="电话"
          initialValue={employee ? employee.phone : null}
          rules={[{ required: true, message: "请输入电话" }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="role"
          label="角色"
          initialValue={employee ? employee.roles[0].id : null}
          rules={[{ required: true, message: "请选择一个角色" }]}
        >
          <Card
            title={
              <Radio.Group
                onChange={(e) => {
                  setSelectedRole(
                    companyRoles.find((role) => role.id == e.target.value)
                  );
                }}
                defaultValue={employee ? employee.roles[0].id : null}
              >
                {companyRoles.map((role) => (
                  <Radio key={role.id} value={role.id}>
                    {role.name}
                  </Radio>
                ))}
              </Radio.Group>
            }
            headStyle={{ backgroundColor: "#f6f6f6" }}
          >
            <Row gutter={16}>
              {abilityCategories.map((abilityCategory, idx) => (
                <Fragment key={idx}>
                  <Col span={12}>
                    <List
                      header={<span>{abilityCategory.name}</span>}
                      bordered
                      dataSource={abilityCategory.abilities}
                      renderItem={(ability: {
                        id: number;
                        name: string;
                        abilityCategoryId: number;
                      }) => (
                        <List.Item>
                          <Checkbox
                            checked={
                              selectedRole
                                ? selectedRole.abilities
                                    .map((el) => el.id)
                                    .includes(ability.id)
                                : false
                            }
                          >
                            {ability.name}
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                  </Col>
                  {idx === 1 && (
                    <Divider
                      style={{
                        margin: "12px 0",
                        borderTopColor: "transparent",
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </Row>
          </Card>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditRoleDrawer;
