import React, { useState, FC } from "react";
import { Tabs } from "antd";
import OrderModeTab from "./components/OrderModeTab";
import {
  OrderStatusTypes,
  OrderStatusModel,
} from "./components/order.model";
import OrderGroupTable from "./components/OrderGroupTable";

const { TabPane } = Tabs;
const SalesOrders: FC = () => {
  const [update, setUpdate] = useState(1);
  const salesOrderCount = [2, 3, 4, 5, 0];
  return (
    <>
      <Tabs
        defaultActiveKey={OrderStatusTypes.PENDING_FORWARD.key}
        animated={false}
      >
        {Object.entries(OrderStatusTypes).map(([key, value], idx) => {
          if (
            key !== OrderStatusTypes.PENDING_FORWARD.key &&
            key !== OrderStatusTypes.RETURNED.key
          ) {
            return (
              <TabPane
                key={key}
                tab={
                  <OrderModeTab dot={salesOrderCount[idx]}>
                    {value.tabText}
                  </OrderModeTab>
                }
              >
                <OrderGroupTable
                  orderMode="sales"
                  orderStatus={key}
                  refreshCount={() => setUpdate(update + 1)}
                />
              </TabPane>
            );
          }
        })}
      </Tabs>
    </>
  );
};

export default SalesOrders;
