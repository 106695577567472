import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Button, Checkbox, Select } from "antd";
import { useUploadOneLocalImage } from "../../../components/Upload";


const { Option } = Select;

const CreateProductForm = ({
  form,
  product,
  categories,
  selectedCategoryId,
  selectedSubcategoryId,
  uploadImage, // 用来显示图片的组件
  setImage, // 用于更新 [被显示的image]
}) => {
  const [categoryId, setCategoryId] = useState(selectedCategoryId);
  const [showCustom, setShowCustom] = useState(false);
  const categoryChanged = (key) => {
    setCategoryId(key);
  };

  const renderSubcategories = () => {
    return (
      <Select placeholder="请选择子类">
        {categories
          .filter((category) => category.id == categoryId)
          .map((cat) =>
            cat.subcategories.map((sub) => (
              <Option key={sub.id} value={sub.id}>
                {sub.name}
              </Option>
            ))
          )}
      </Select>
    );
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    form.setFieldsValue({
      id: product ? product.id : undefined,
      productName: product ? product.name : undefined,
      categoryId: product ? product.categoryId : selectedCategoryId,
      subcategoryId: product ? product.subcategoryId : selectedSubcategoryId,
      unitPrice: product ? product.unitPrice : undefined,
      unit: product ? product.unit : "斤",
      customUnit: product ? product.customUnit : undefined,
      desc: product ? product.desc : undefined,
    });
    if (product && product.unit === "custom") {
      setShowCustom(true);
      form.setFieldsValue({
        customUnit: product.customUnit,
      });
    }
    if (product && product.img) {
      setImage(product.img);
    } else {
      setImage();
    }
  }, []);

  return (
    <Form form={form} {...formItemLayout}>
      <Form.Item
        name="id"
        // initialValue={}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="productName"
        label="商品名"
        // initialValue={product ? product.name : undefined}
        rules={[{ required: true, message: "请输入商品名" }]}
      >
        <Input
          //   prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="商品名"
        />
      </Form.Item>
      <Form.Item
        name="categoryId"
        label="品类"
        // initialValue={product ? product.categoryId : selectedCategoryId}
        rules={[{ required: true, message: "请选择品类" }]}
      >
        <Select placeholder="请选择品类" onChange={categoryChanged}>
          {categories.map((category) => {
            return (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="subcategoryId"
        label="子类"
        initialValue={product ? product.subcategoryId : selectedSubcategoryId}
        rules={[{ required: true, message: "请选择品类" }]}
      >
        {renderSubcategories()}
      </Form.Item>
      <Form.Item
        name="unitPrice"
        label="销售价"
        validateTrigger={["onChange", "onBlur"]}
        rules={[
          {
            required: true,
            // whitespace: true,
            message: "请输入单价",
          },
        ]}
      >
        <InputNumber
          name="unitPrice"
          min={0}
          placeholder="0.00"
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          initialValue={product ? product.unitPrice : null}
        />
      </Form.Item>

      <Form.Item
        label="单位"
        name="unit"
        // initialValue={product ? product.unit : "斤"}
        validateTrigger={["onChange", "onBlur"]}
        rules={[
          {
            required: true,
            message: "请选择单位",
          },
        ]}
      >
        <Select
          style={{ width: "30%" }}
          placeholder="请选择单位"
          onChange={(e) => {
            if (form.getFieldValue("unit") === "custom") {
              setShowCustom(true);
            } else {
              setShowCustom(false);
            }
          }}
        >
          <Option value="斤">斤</Option>
          <Option value="公斤">公斤</Option>
          <Option value="个">个</Option>
          <Option value="custom">自定义单位</Option>
        </Select>
      </Form.Item>
      {showCustom && (
        <Form.Item label="自定义单位" name="customUnit">
          <Input
            placeholder="例如：瓶"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                message: "请输入自定义单位（例如：瓶）",
              },
            ]}
          />
        </Form.Item>
      )}

      <Form.Item
        name="desc"
        label="描述"
        initialValue={product ? product.desc : null}
        rules={[{ required: false, message: "请给商品一些描述" }]}
      >
        <Input placeholder="描述、规格" />
      </Form.Item>

      <Form.Item name="img" label="图片">
        {uploadImage}
      </Form.Item>
    </Form>
  );
};

export default CreateProductForm;
