import TaizhangPage from "./index";
import AppLayout from "../../../components/_layout";

const taizhangRoutes = {
  path: "/taizhang",
  component: AppLayout,
  childRoutes: [
    {
      path: "",
      component: TaizhangPage,
    },
  ],
};

export default taizhangRoutes;
