import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import {
  ButtonWithDrawerContext,
  CallbackStatus,
} from '../../../../components/ButtonWithDrawer';
import { Form, Tag, DatePicker, Input, Select, message } from 'antd';
import { useMutation } from '@apollo/client';

import {
  CREATE_PIAOZHENG_MUTATION,
  UPDATE_PIAOZHENG_MUTATION,
  DELETE_PIAOZHENG_IMAGE,
} from '../../../../graphql/piaozheng';
import {
  useUploadOneLocalImage,
  useUploadMultiple,
} from '../../../../components/Upload';
import { convertImage } from '..';

const { CheckableTag } = Tag;
const { Option } = Select;

const UploadPiaoZhengFromLocal = ({
  companyId,
  piaozheng,
  companies,
  categories,
  suppliers,
  callback = undefined,
}) => {
  const existingImages = convertImage(piaozheng);
  const context = useContext(ButtonWithDrawerContext);

  const [createPiaozhengMutation] = useMutation(
    CREATE_PIAOZHENG_MUTATION,
  );
  const [updatePiaozhengMutation] = useMutation(
    UPDATE_PIAOZHENG_MUTATION,
  );
  const [deletePiaozhengImageMutation] = useMutation(
    DELETE_PIAOZHENG_IMAGE,
  );

  const [UploadList, images] = useUploadMultiple({
    accept: 'image/*',
    existingImages: existingImages,
    piaozhengId: piaozheng ? piaozheng.id : undefined,
    deleteImageCallback: deletePiaozhengImageMutation,
  });

  const [form] = Form.useForm();

  const [changed, setChanged] = useState(0);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(
    piaozheng ? piaozheng.categories.map((cat) => cat.id) : [],
  );
  const [selectedSupplierIds, setSelectedSupplierIds] = useState(
    piaozheng ? piaozheng.suppliers.map((s) => s.id) : [],
  );

  function handleChange(category, checked) {
    const nextSelectedCategoryIds = checked
      ? [...selectedCategoryIds, category.id]
      : selectedCategoryIds.filter((t) => t !== category.id);
    setSelectedCategoryIds(nextSelectedCategoryIds);
  }

  function handleSupplierChange(supplier, checked) {
    const nextSelectedSupplierIds = checked
      ? [...selectedSupplierIds, supplier.id]
      : selectedSupplierIds.filter((t) => t !== supplier.id);
    setSelectedSupplierIds(nextSelectedSupplierIds);
  }

  function handleSubmit() {
    const theMutation = piaozheng
      ? updatePiaozhengMutation
      : createPiaozhengMutation;

    form.validateFields().then((values) => {
      console.log('form values', values);

      theMutation({
        variables: {
          piaozheng: {
            id: piaozheng ? piaozheng.id : undefined,
            name: values.name,
            companyId: values.companyId,
            categoryIds: selectedCategoryIds,
            supplierCateIds: selectedSupplierIds,
            date: values.date.format('YYYY-MM-DD'),
            desc: values.desc,
            imageFiles: images,
          },
        },

        update: (cache, mutationResult) => {
          if (mutationResult.data) {
            if (callback) {
              callback({
                status: CallbackStatus.Success,
                message: ['Data saved.'],
              });
            }
            message.success('操作成功');
            // history.push(`/orders`);
          }
        },
      });
    });
  }

  useEffect(() => {
    setChanged(changed + 1);
  }, [images]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && changed) {
      handleSubmit();
    }
    return () => {
      isSubscribed = false;
    };
  }, [context]);

  return (
    <Form form={form}>
      <Form.Item
        name="name"
        label="票据名"
        initialValue={piaozheng ? piaozheng.name : null}
      >
        <Input
          placeholder="票据名"
          onChange={() => setChanged(changed + 1)}
        />
      </Form.Item>
      <Form.Item
        name="companyId"
        label="档口"
        initialValue={piaozheng ? piaozheng.companyId : null}
      >
        <Select placeholder="请选择档口">
          {companies.map((company) => (
            <Option key={company.id} value={company.id}>
              {company.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="categories" label="品类">
        {categories.map((category) => (
          <CheckableTag
            key={category.id}
            checked={selectedCategoryIds.indexOf(category.id) > -1}
            onChange={(checked) => handleChange(category, checked)}
          >
            {category.name}
          </CheckableTag>
        ))}
      </Form.Item>
      <Form.Item name="suppliers" label="供应商">
        {suppliers.map((supplier) => {
          return (
            <CheckableTag
              key={supplier.id}
              checked={selectedSupplierIds.indexOf(supplier.id) > -1}
              onChange={(checked) =>
                handleSupplierChange(supplier, checked)
              }
            >
              {supplier.name}
            </CheckableTag>
          );
        })}
      </Form.Item>
      <Form.Item
        name="date"
        label="日期"
        initialValue={piaozheng ? moment(piaozheng.date) : moment()}
      >
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item name="img" label="证件图片">
        {/* {UploadLocal} */}
        {UploadList}
      </Form.Item>
    </Form>
  );
};

export default UploadPiaoZhengFromLocal;
