import gql from "graphql-tag";

export const USER_FRAGMENT = gql`
  fragment User on UserType {
    id
    userName
    realName
    phone
    userStatus
  }
`;

export const ABILITY_CATEGORY_FRAGMENT = gql`
  fragment AbilityCategory on AbilityCategoryType {
    id
    name
  }
`;

export const ABILITY_FRAGMENT = gql`
  fragment Ability on AbilityType {
    id
    abilityCategoryId
    name
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment Role on RoleType {
    id
    name
  }
`;

export const EMPLOYEES_PAGE = gql`
  query EMPLOYEES_PAGE {
    abilityCategories {
      ...AbilityCategory
      abilities {
        ...Ability
      }
    }

    companyRoles {
      ...Role
      abilities {
        ...Ability
      }
    }

    employeesPage {
      total
      items {
        ...User
        roles {
          id
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${ROLE_FRAGMENT}
  ${ABILITY_CATEGORY_FRAGMENT}
  ${ABILITY_FRAGMENT}
`;

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UPDATE_EMPLOYEE_MUTATION(
    $userId: Int!
    $realName: String!
    $phone: String!
    $oldRoleId: Int!
    $newRoleId: Int!
  ) {
    updateEmployee(
      userId: $userId
      realName: $realName
      phone: $phone
      oldRoleId: $oldRoleId
      newRoleId: $newRoleId
    ) {
      ok
      msg
    }
  }
`;
