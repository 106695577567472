import gql from "graphql-tag";

export const CATEGORY_FRAGMENT = gql`
  fragment Category on ProductCategoryType {
    id
    name
  }
`;

export const SUBCATEGORY_FRAGMENT = gql`
  fragment Subcategory on ProductSubcategoryType {
    id
    name
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment Product on ProductType {
    id
    name
    productStatus
    unitPrice
    unit
    customUnit
    companyId
    categoryId
    subcategoryId
    fromProductId
    desc
    images
  }
`;

export const CATEGORIES = gql`
  query Categories {
    productCategories {
      id
      name
      subcategories {
        id
        name
        categoryId
      }
    }
  }
`;

export const PRODUCT_PAGE = gql`
  query ProductPageQuery(
    $current: Int
    $pageSize: Int
    $companyId: Int
    $categoryId: Int
  ) {
    productPage(
      pageNum: $current
      pageSize: $pageSize
      companyId: $companyId
      categoryId: $categoryId
    ) {
      total
      items {
        ...Product
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const SUPPLIER_PRODUCT_PAGE = gql`
  query SUPPLIER_PRODUCT_PAGE(
    $current: Int
    $pageSize: Int
    $supplierId: Int
    $categoryId: Int
  ) {
    productPage(
      pageNum: $current
      pageSize: $pageSize
      companyId: $supplierId
      categoryId: $categoryId
    ) {
      total
      items {
        ...Product
      }
    }
    supplierCategories: productCategories(companyId: $supplierId) {
      id
      name
      subcategories {
        id
        name
      }
    }
    categories: productCategories {
      id
      name
      subcategories {
        id
        name
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const CATEGORY_PRODUCTS = gql`
  query CATEGORY_PRODUCTS($categoryId: Int) {
    categoryProducts(categoryId: $categoryId) {
      id
    }
  }
`;

export const MANAGE_PRICE_GROUP_PAGE = gql`
  query MANAGE_PRICE_GROUP_PAGE {
    priceGroups {
      id
      name
      clients {
        id
        name
        address
        contact
      }
    }
  }
`;

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CREATE_PRODUCT_MUTATION($product: ProductInput!) {
    createProduct(product: $product) {
      ok
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UPDATE_PRODUCT_MUTATION($product: ProductInput!) {
    updateProduct(product: $product) {
      ok
    }
  }
`;

export const DELETE_PRODUCT_MUTATION = gql`
  mutation DELETE_PRODUCT_MUTATION($productId: Int!) {
    deleteProduct(productId: $productId) {
      ok
    }
  }
`;

export const BULK_DELETE_PRODUCTS_MUTATION = gql`
  mutation BULK_DELETE_PRODUCTS_MUTATION($productIds: [Int]!) {
    bulkDeleteProducts(productIds: $productIds) {
      ok
    }
  }
`;

export const SET_IN_STOCK_MUTATION = gql`
  mutation SET_IN_STOCK_MUTATION($productId: Int!, $inStock: Boolean!) {
    setInStock(productId: $productId, inStock: $inStock) {
      ok
    }
  }
`;

export const BULK_SET_IN_STOCK_MUTATION = gql`
  mutation BULK_SET_IN_STOCK_MUTATION($productIds: [Int]!, $inStock: Boolean!) {
    bulkSetInStock(productIds: $productIds, inStock: $inStock) {
      ok
    }
  }
`;

export const FORK_PRODUCT_MUTATION = gql`
  mutation FORK_PRODUCT_MUTATION($productId: ID!) {
    forkProduct(productId: $productId) {
      ok
    }
  }
`;

export const IMPORT_PRODUCTS_MUTATION = gql`
  mutation IMPORT_PRODUCTS_MUTATION(
    $productIds: [Int]!
    $toCategoryId: Int!
    $toSubcategoryId: Int!
  ) {
    importProducts(
      productIds: $productIds
      toCategoryId: $toCategoryId
      toSubcategoryId: $toSubcategoryId
    ) {
      ok
    }
  }
`;

export const UPLOAD_PRODUCT_EXCEL_MUTATION = gql`
  mutation UPLOAD_PRODUCT_EXCEL($fileIn: Upload) {
    uploadProductExcel(fileIn: $fileIn) {
      ok
    }
  }
`;

/* Category Mutation */
export const CREATE_CATEGORY_MUTATION = gql`
  mutation CREATE_CATEGORY_MUTATION($categoryName: String!) {
    createCategory(categoryName: $categoryName) {
      ok
      categoryId
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UPDATE_CATEGORY_MUTATION($categoryId: Int!, $categoryName: String!) {
    updateCategory(categoryId: $categoryId, categoryName: $categoryName) {
      ok
    }
  }
`;

export const DELETE_CATEGORY_MUTATION = gql`
  mutation DELETE_CATEGORY_MUTATION($categoryId: Int!) {
    deleteCategory(categoryId: $categoryId) {
      ok
    }
  }
`;

/* Subcategory Mutation */
export const CREATE_SUBCATEGORY_MUTATION = gql`
  mutation CREATE_SUBCATEGORY_MUTATION(
    $categoryId: Int!
    $subcategoryName: String!
  ) {
    createSubcategory(
      categoryId: $categoryId
      subcategoryName: $subcategoryName
    ) {
      ok
    }
  }
`;

export const UPDATE_SUBCATEGORY_MUTATION = gql`
  mutation UPDATE_SUBCATEGORY_MUTATION(
    $subcategoryId: Int!
    $subcategoryName: String!
  ) {
    updateSubcategory(
      subcategoryId: $subcategoryId
      subcategoryName: $subcategoryName
    ) {
      ok
    }
  }
`;

/* Price Group Mutation */
export const CREATE_PRICE_GROUP_MUTATION = gql`
  mutation CREATE_PRICE_GROUP_MUTATION($priceGroupName: String!) {
    createPriceGroup(priceGroupName: $priceGroupName) {
      ok
      priceGroupId
    }
  }
`;

export const UPDATE_PRICE_GROUP_MUTATION = gql`
  mutation UPDATE_PRICE_GROUP_MUTATION(
    $priceGroupId: Int!
    $priceGroupName: String!
  ) {
    updatePriceGroup(
      priceGroupId: $priceGroupId
      priceGroupName: $priceGroupName
    ) {
      ok
    }
  }
`;

export const GET_PRICE_GROUP_CONFLICTS = gql`
  mutation GET_PRICE_GROUP_CONFLICTS($companyIds: [Int]!, $priceGroupId: Int!) {
    getPriceGroupConflicts(
      companyIds: $companyIds
      priceGroupId: $priceGroupId
    ) {
      ok
      conflictCompanyNames
    }
  }
`;

export const UPDATE_COMPANIES_IN_PRICE_GROUP = gql`
  mutation UPDATE_COMPANIES_IN_PRICE_GROUP(
    $companyIds: [Int]!
    $priceGroupId: Int!
  ) {
    updateCompaniesInPriceGroup(
      companyIds: $companyIds
      priceGroupId: $priceGroupId
    ) {
      ok
    }
  }
`;

export const DELETE_COMPANY_FROM_PRICE_GROUP = gql`
  mutation DELETE_COMPANY_FROM_PRICE_GROUP(
    $companyId: Int!
    $priceGroupId: Int!
  ) {
    deleteCompanyFromPriceGroup(
      companyId: $companyId
      priceGroupId: $priceGroupId
    ) {
      ok
    }
  }
`;

export const BULK_UPDATE_CUSTOM_PRICES = gql`
  mutation bulkUpdateCustomPrices(
    $priceGroupId: Int!
    $customPrices: [CustomPriceInput]!
  ) {
    bulkUpdateCustomPrices(
      priceGroupId: $priceGroupId
      customPrices: $customPrices
    ) {
      ok
    }
  }
`;

export const BULK_UPDATE_PRODUCT_CATEGORY = gql`
  mutation bulkUpdateProductCategory(
    $productIds: [Int]!
    $toCategoryId: Int!
    $toSubcategoryId: Int!
  ) {
    bulkUpdateProductCategory(
      productIds: $productIds
      toCategoryId: $toCategoryId
      toSubcategoryId: $toSubcategoryId
    ) {
      ok
    }
  }
`;
