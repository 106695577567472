import React, { useState, FC, ReactElement } from "react";
import { Drawer, Button, Space, Result, Popconfirm, Spin } from "antd";

export const ButtonWithDrawerContext = React.createContext(0);

export enum CallbackStatus {
  None = "",
  Success = "success",
  Warning = "warning",
  Error = "error",
}
export interface CallbackData {
  status: CallbackStatus;
  messageTitle?: string;
  message?: string[];
  data?: any;
  byConfirm?: boolean;
}
/*
1. Aoubt children's callback:
    -- If children has callback defined, the drawer will trigger chidren to process its data, and then display the results 
       such as success, warning, or error. And if onConfirm is defined, when the result is CallbackStatus.Success, the data will
       also be notified to the drawer's parent page to refetch data.
    -- The callback will be called when the Drawer's confirm button is clicked.
    -- The callback is optional. When chihldren has defined it(typeof CallbackData), children also need to define the 
       following two lines of code. Otherwise, the 2 lines of codes are also not required:
        * const context = useContext(ButtonWithDrawerContext);
        * useEffect to call the callback
    -- The callback doesn't have to be called by the drawer's Confirm button. You can hide the Confirm
        button(hasConfirm={false}), and add a button in children that will:
        onClick={()=>{callback && callback({
            status: CallbackStatus.Success,
            message: ["Data saved."],
            byConfirm: false,
        });}}
        Note: In this case, callback, byConfirm:false are defined. The two lines of code mentioned in previous section are not required.
    -- If doubleConfirm is defined, clicking Confirm button will again popup another Confirm window for a 2nd confirm.
*/
export const ButtonWithDrawer = ({
  children,
  button,
  width,
  title,
  hasConfirm,
  onConfirm,
  doubleConfirm,
  maskClosable,
  closeOnSuccess,
}: {
  children: ReactElement<{
    callback?: (data: CallbackData) => void;
    children?: any;
  }>;
  button: ReactElement<any>;
  width: number;
  title: string;
  // onConfirm: (data: any) => Promise<{ ok: boolean; message: string }>;
  onConfirm?: (data?: any) => void;
  hasConfirm?: boolean;
  doubleConfirm?: boolean;
  maskClosable?: boolean;
  closeOnSuccess?: boolean;
}) => {
  const [confirmCount, setConfirmCount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callbackData, setCallbackData] = useState<CallbackData | null>(null); // will pass to parent by handleConfirm to process

  const handleCallbackByDrawer = (data: CallbackData) => {
    if (confirmCount === 0 && data.byConfirm !== false) return;
    setLoading(false);
    setCallbackData(data);
    if (onConfirm && data && String(data.status) === CallbackStatus.Success) {
      // Notify parent page the confirm operation is successful
      data.data ? onConfirm(data.data) : onConfirm();

      if(closeOnSuccess) {
        closeDrawer();
      }
    }
  };

  function handleConfirm() {
    setLoading(true);
    if (callbackData && callbackData.status !== CallbackStatus.None) {
      setCallbackData({ ...callbackData, status: CallbackStatus.None });
    }
    setConfirmCount(confirmCount + 1);
  }

  function getResultTitle(forSubtitle) {
    let title = "";
    if (forSubtitle) {
      title = callbackData?.message?.join("<br />") || "";
      if (!title) {
        if (callbackData?.status === CallbackStatus.Success) {
          title = "信息保存成功了";
        }
      }
    } else {
      title = callbackData?.messageTitle || "";
      if (!title) {
        if (callbackData?.status === CallbackStatus.Success) {
          title = "操作成功";
        } else if (callbackData?.status === CallbackStatus.Warning) {
          title = "数据验证问题";
        } else if (callbackData?.status === CallbackStatus.Error) {
          title = "操作失败";
        }
      }
    }
    return title;
  }

  function openDrawer() {
    setLoading(false);
    setCallbackData(null);
    setConfirmCount(0); // when 0, handleCallbackByDrawer won't process callback data
    setDrawerVisible(true);
  }

  function closeDrawer() {
    setDrawerVisible(false);
  }

  function getDrawerFooter() {
    const disabled =
      loading ||
      (!!callbackData &&
        (callbackData.status === "success" || callbackData.status === "error"));
    return (
      <div className="text-right">
        <Space>
          <Button onClick={closeDrawer} type="default">
            关闭
          </Button>
          {!doubleConfirm && hasConfirm !== false && (
            <Button
              onClick={handleConfirm}
              type="primary"
              loading={loading}
              disabled={disabled}
            >
              确定
            </Button>
          )}
          {doubleConfirm && (
            <Popconfirm
              title="确认？"
              onConfirm={handleConfirm}
              okText="是"
              cancelText="否"
            >
              <Button type="primary" loading={loading} disabled={disabled}>
                确定
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
    );
  }

  return (
    <ButtonWithDrawerContext.Provider value={confirmCount}>
      {React.cloneElement(
        button,
        { ...button.props, onClick: openDrawer },
        button.props.children
      )}

      <Drawer
        title={title}
        placement="right"
        closable={true}
        mask={true}
        onClose={closeDrawer}
        width={window.innerWidth > width + 100 ? width : window.innerWidth - 50}
        visible={drawerVisible}
        footer={getDrawerFooter()}
        maskClosable={maskClosable === false ? false : true}
      >
        {callbackData !== null &&
          callbackData.status !== CallbackStatus.None && (
            <Result
              status={callbackData.status as any}
              title={getResultTitle(0)}
              subTitle={
                <div dangerouslySetInnerHTML={{ __html: getResultTitle(1) }} />
              }
            />
          )}
        <Spin spinning={loading}>
          {(!callbackData ||
            callbackData.status === CallbackStatus.None ||
            callbackData.status === CallbackStatus.Warning) &&
            (typeof children.type === "function"
              ? React.createElement(
                  children.type,
                  { ...children.props, callback: handleCallbackByDrawer },
                  children.props.children ? children.props.children : null
                )
              : children)}
        </Spin>
      </Drawer>
    </ButtonWithDrawerContext.Provider>
  );
};

//-- Drawer has no way to know if the children has declared the callback or not, but the handler is added to children anyway.
//-- The chidren is expecting to be a functional component. If chidren is just something like <h2>Test</h2>, typeof children.type !== "function", then we just need to include children.

/*
<ButtonWithDrawer
    button={<Button type="link">设置</Button>}
    width={800}
    title={supplier.name}
    onConfirm={refetch}
    maskClosable={false}
>
    <SetSupplierPermission initData={supplier} />
</ButtonWithDrawer>

//--- cchildren code ----
const SetSupplierPermission = ({
  initData, //supplier
  callback,
}: {
  initData?: any;
  callback?: (data: any) => void;
}) => {
  const context = useContext(ButtonWithDrawerContext);
  const [data, setData] = useState<any>(initData);

  function startCallback() {
    if (callback) {
        // perform validataion and data updates
        // then will:
        // callback({ status: CallbackStatus.Success });
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && data && data !== initData) {
      startCallback();
    }
    return () => {
      isSubscribed = false;
    };
  }, [context]);
  return (
    ......
  );
};

// ------------ Following is demo for a child inner button which will callback when clicked -------


<ButtonWithDrawer
    width={700}
    title="Hello"
    hasConfirm={false}
    button={<Button type="primary">Test 2</Button>}
>
        <h2>Foo</h2>
</ButtonWithDrawer> 

//--- cchildren code ----

const AddSupplier = ({
  callback,
}: {
  callback?: (data: CallbackData) => void;
}) => {
  function test2() {
    callback &&
      callback({
        status: CallbackStatus.Success,
        message: ["Data saved."],
        byConfirm: false,
      });
  }

  return (
    <>
      <Button onClick={test2} type="default">
        Test
      </Button>
    </>
  );
*/
