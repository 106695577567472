import React, { useRef, useEffect } from 'react'


/**
 * 分页hooks
 * current 当前页
 * pageSize 大小
 */
export function usePager({ pageNum = 1, pageSize = 10 }) {
  const [pager, setPager] = React.useState({ pageNum, pageSize });
  const handlePagerChange = (...args) => {
    // console.log(args);
    //2个参数是 Pagination 组件。
    //pageNumber, pageSize
    if (args.length === 2) {
      setPager({ pageNum: args[0], pageSize: args[1] });
    } else {
      //4个参数是 Table Pagination 组件。
      // console.log({ ...args[0] });
      setPager({ ...args[0] });
    }
  }
  return { pager, setPager, handlePagerChange }
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}