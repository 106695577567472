import jwt_decode from "jwt-decode";
import _ from "lodash";

export function decodeJwt(jwt) {
  if (jwt && jwt !== "null" && jwt !== "undefined") {
    try {
      const token = jwt_decode(jwt);
      const elapsed = Math.floor(Date.now() / 1000) - token.exp;
      if (elapsed > 0) {
        //过期 jwt
        localStorage.clear();
        return null;
      }
      return token;
    } catch (error) {
      console.error(jwt, error);
      localStorage.clear();
      return null;
    }
  }
  return null;
}
